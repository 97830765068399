.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: #333;
  font-family: roboto;
  font-size: 14px; }

.Toastify__toast-theme--colored.Toastify__toast--default {
  font-family: roboto;
  font-size: 14px; }

.Toastify__toast-theme--colored.Toastify__toast--error {
  font-family: roboto;
  font-size: 14px; }

.Toastify__toast-theme--colored.Toastify__toast--info {
  font-family: roboto;
  font-size: 14px; }

.Toastify__toast-theme--colored.Toastify__toast--success {
  font-family: roboto;
  font-size: 14px; }

.LoginForm__inner {
  margin: 0 auto;
  max-width: 1140px;
  padding: 0 24px;
  margin-top: 192px; }
