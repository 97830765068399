.AppNavigation__brand {
  align-items: center;
  border-bottom: 1px solid #dfdfdf;
  display: flex;
  flex-direction: row;
  height: 80px; }

.AppNavigation__logo {
  position: absolute;
  max-width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 16px;
  bottom: 48px; }
  .AppNavigation__logo--collapsed {
    display: none; }

.AppNavigation__JustGO {
  max-width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 16px; }
  .AppNavigation__JustGO--collapsed {
    opacity: 1;
    height: 12px;
    padding-left: 12px;
    padding-right: 14px;
    margin-top: 16px;
    margin-bottom: 8px; }

.AppNavigation__title {
  line-height: 1em;
  font-weight: bold;
  opacity: 0.95;
  text-align: center;
  margin-bottom: 26px; }
  .AppNavigation__title-user {
    font-size: 12px;
    font-weight: 300; }
  .AppNavigation__title--collapsed {
    display: none; }

.AppNavigation__item {
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  font-weight: 300;
  border-bottom: 1px solid #dfdfdf;
  color: rgba(0, 0, 0, 0.54);
  display: flex;
  flex-direction: row;
  font-size: 13px;
  font-weight: 500;
  padding: 12px 0;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 200ms ease-in-out; }
  .AppNavigation__item:hover {
    background-color: #edf0f2;
    color: black; }
  .AppNavigation__item:focus {
    background-color: #edf0f2;
    outline: none; }
  .AppNavigation__item--selected {
    background-color: #edf0f2; }
  .AppNavigation__item--logout:hover {
    background-color: #ff8e64; }
  .AppNavigation__item-icon {
    background-position: center;
    background-size: cover;
    height: 24px;
    width: 24px;
    margin: 0 16px 0 20px; }
    .AppNavigation__item-icon--provider {
      background-image: url("../../images/material-icons/ic_usb_black_24px.svg");
      opacity: 1; }
      .AppNavigation__item-icon--provider-selected {
        background-image: url("../../images/material-icons/ic_usb_viridian_24px.svg"); }
    .AppNavigation__item-icon--models {
      background-image: url("../../images/material-icons/ic_view_in_ar_black_rounded_24px.svg");
      opacity: 0.9; }
      .AppNavigation__item-icon--models-selected {
        background-image: url("../../images/material-icons/ic_view_in_ar_black_two_tone_24px.svg");
        opacity: 1; }
    .AppNavigation__item-icon--releases, .AppNavigation__item-icon--release-notes-page {
      background-image: url("../../images/material-icons/sticky_note_2_black_24dp.svg");
      opacity: 0.5; }
      .AppNavigation__item-icon--releases-selected, .AppNavigation__item-icon--release-notes-page-selected {
        background-image: url("../../images/material-icons/sticky_note_2_black_24dp.svg");
        opacity: 1; }
    .AppNavigation__item-icon--translations-page {
      background-image: url("../../images/material-icons/translate_opsz24.svg");
      opacity: 0.5; }
      .AppNavigation__item-icon--translations-page-selected {
        background-image: url("../../images/material-icons/translate_opsz24.svg");
        opacity: 1; }
    .AppNavigation__item-icon--access-keys {
      background-image: url("../../images/material-icons/key_opsz24.svg");
      opacity: 0.5; }
      .AppNavigation__item-icon--access-keys-selected {
        background-image: url("../../images/material-icons/key_opsz24.svg");
        opacity: 1; }
    .AppNavigation__item-icon--logout {
      background-image: url("../../images/material-icons/ic_close_reddish_18px.svg"); }
    .AppNavigation__item-icon--brand {
      background-image: url("../../images/material-icons/ic_branding_watermark_black_24px.svg"); }
      .AppNavigation__item-icon--brand-selected {
        background-image: url("../../images/material-icons/ic_branding_watermark_viridian_24px.svg"); }
    .AppNavigation__item-icon--fueltype {
      background-image: url("../../images/material-icons/ic_local_gas_station_black_24px.svg"); }
      .AppNavigation__item-icon--fueltype-selected {
        background-image: url("../../images/material-icons/ic_local_gas_station_viridian_24px.svg"); }
  .AppNavigation__item-text {
    margin-left: 2px; }
    .AppNavigation__item-text--collapsed {
      display: none; }
    .AppNavigation__item-text--selected {
      color: #03854a; }
