.PPClone__content {
  border-top: solid;
  border-color: #a0a0a0;
  border-width: 1px; }

.PPClone__title {
  font-weight: bold; }

.PPClone__options {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px; }
  .PPClone__options-title {
    margin-bottom: 0; }
  .PPClone__options-search {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end; }
    .PPClone__options-searchfield {
      background: #fff;
      border-radius: 2px;
      border: 1px solid #bfbfbf;
      height: 36px;
      padding: 8px; }
      .PPClone__options-searchfield:focus {
        outline: none;
        border-color: #0dac64; }

.PPClone__templates-title {
  font-weight: bold;
  display: inline-block;
  padding-right: 8px; }

.PPClone__templates-subtitle {
  display: inline-block; }

.PPClone__prices {
  padding-top: 16px;
  padding-bottom: 16px;
  border-top: solid;
  border-color: #a0a0a0;
  border-width: 1px;
  margin-top: 24px; }

.PPClone__label {
  padding-left: 16px; }

.PPClone__actions {
  padding-top: 16px;
  float: right; }

.PPClone__button {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid transparent;
  color: #03854a;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  margin: 6px;
  min-width: 64px;
  padding: 0 16px;
  position: relative;
  text-align: center;
  transition: all 250ms ease-in-out;
  background-color: #0dac64;
  color: #fff; }
  .PPClone__button:focus {
    outline: none; }
  .PPClone__button:hover {
    cursor: pointer; }
  .PPClone__button:disabled, .PPClone__button--disabled {
    opacity: 0.4;
    box-shadow: none !important; }
  .PPClone__button:focus {
    background-color: #097d48; }
