.ReleaseDescription__label {
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  font-weight: 300;
  margin: 1em 0 0.5em;
  line-height: 1.2em;
  padding: 0;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 400;
  margin-top: 1.25em;
  margin-bottom: 0.05em; }
