.Equalizer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center; }
  .Equalizer__animation {
    margin: 0 auto 4px;
    width: 50px;
    height: 40px;
    text-align: center;
    font-size: 10px; }
    .Equalizer__animation > div {
      background-color: #008b7d;
      height: 100%;
      width: 6px;
      display: inline-block;
      margin-right: 3px;
      animation: sk-stretchdelay 1.2s infinite ease-in-out; }
    .Equalizer__animation-2 {
      animation-delay: -1.1s !important; }
    .Equalizer__animation-3 {
      animation-delay: -1s !important; }
    .Equalizer__animation-4 {
      animation-delay: -0.9s !important; }
    .Equalizer__animation-5 {
      animation-delay: -0.8s !important; }
  .Equalizer__text {
    color: rgba(0, 0, 0, 0.87);
    font-size: 20px;
    font-weight: 500;
    color: #008b7d;
    text-align: center;
    text-transform: uppercase; }

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4); }
  20% {
    transform: scaleY(1); } }
