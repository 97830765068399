.PPStripeDetails {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12); }
  .PPStripeDetails__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 32px;
    z-index: 200; }
  .PPStripeDetails__actions {
    display: flex;
    flex: 1;
    justify-content: flex-end; }
    .PPStripeDetails__actions > *:first-child {
      margin-left: 0; }
  .PPStripeDetails__options {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end; }
    .PPStripeDetails__options > *:last-child {
      margin-left: 0; }
  .PPStripeDetails__features {
    display: flex;
    justify-content: flex-start; }
    .PPStripeDetails__features > *:first-child {
      margin-left: 0; }
  .PPStripeDetails__content {
    z-index: 100; }
  .PPStripeDetails__header {
    position: sticky;
    top: 0;
    background-color: #fff; }
    .PPStripeDetails__header--sticky {
      border-bottom: 1px solid #dfdfdf; }
  .PPStripeDetails__title {
    line-height: 48px;
    margin: 0; }
  .PPStripeDetails__info {
    flex-grow: 1; }
  .PPStripeDetails__editbutton {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid transparent;
    color: #03854a;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    margin: 6px;
    min-width: 64px;
    padding: 0 16px;
    position: relative;
    text-align: center;
    transition: all 250ms ease-in-out;
    background-color: #0dac64;
    color: #fff; }
    .PPStripeDetails__editbutton:focus {
      outline: none; }
    .PPStripeDetails__editbutton:hover {
      cursor: pointer; }
    .PPStripeDetails__editbutton:disabled, .PPStripeDetails__editbutton--disabled {
      opacity: 0.4;
      box-shadow: none !important; }
    .PPStripeDetails__editbutton:focus {
      outline: none;
      box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1); }
    .PPStripeDetails__editbutton:hover {
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2); }
    .PPStripeDetails__editbutton:focus {
      background-color: #097d48; }
  .PPStripeDetails__inactivatebutton {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid transparent;
    color: #03854a;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    margin: 6px;
    min-width: 64px;
    padding: 0 16px;
    position: relative;
    text-align: center;
    transition: all 250ms ease-in-out;
    background-color: #f99513;
    color: #fff; }
    .PPStripeDetails__inactivatebutton:focus {
      outline: none; }
    .PPStripeDetails__inactivatebutton:hover {
      cursor: pointer; }
    .PPStripeDetails__inactivatebutton:disabled, .PPStripeDetails__inactivatebutton--disabled {
      opacity: 0.4;
      box-shadow: none !important; }
    .PPStripeDetails__inactivatebutton:focus {
      outline: none;
      box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1); }
    .PPStripeDetails__inactivatebutton:hover {
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2); }
    .PPStripeDetails__inactivatebutton:focus {
      background-color: #d47a05; }
  .PPStripeDetails__activatebutton {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid transparent;
    color: #03854a;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    margin: 6px;
    min-width: 64px;
    padding: 0 16px;
    position: relative;
    text-align: center;
    transition: all 250ms ease-in-out;
    background-color: #1566a0;
    color: #fff; }
    .PPStripeDetails__activatebutton:focus {
      outline: none; }
    .PPStripeDetails__activatebutton:hover {
      cursor: pointer; }
    .PPStripeDetails__activatebutton:disabled, .PPStripeDetails__activatebutton--disabled {
      opacity: 0.4;
      box-shadow: none !important; }
    .PPStripeDetails__activatebutton:focus {
      outline: none;
      box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1); }
    .PPStripeDetails__activatebutton:hover {
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2); }
    .PPStripeDetails__activatebutton:focus {
      background-color: #0f4973; }
  .PPStripeDetails__cancelbutton {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid transparent;
    color: #03854a;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    margin: 6px;
    min-width: 64px;
    padding: 0 16px;
    position: relative;
    text-align: center;
    transition: all 250ms ease-in-out;
    background-color: #d2d2d2;
    color: #fff; }
    .PPStripeDetails__cancelbutton:focus {
      outline: none; }
    .PPStripeDetails__cancelbutton:hover {
      cursor: pointer; }
    .PPStripeDetails__cancelbutton:disabled, .PPStripeDetails__cancelbutton--disabled {
      opacity: 0.4;
      box-shadow: none !important; }
    .PPStripeDetails__cancelbutton:focus {
      outline: none;
      box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1); }
    .PPStripeDetails__cancelbutton:hover {
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2); }
    .PPStripeDetails__cancelbutton:focus {
      background-color: #b9b9b9; }
  .PPStripeDetails__resetbutton {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid transparent;
    color: #03854a;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    margin: 6px;
    min-width: 64px;
    padding: 0 16px;
    position: relative;
    text-align: center;
    transition: all 250ms ease-in-out;
    background-color: #d2d2d2;
    color: #fff; }
    .PPStripeDetails__resetbutton:focus {
      outline: none; }
    .PPStripeDetails__resetbutton:hover {
      cursor: pointer; }
    .PPStripeDetails__resetbutton:disabled, .PPStripeDetails__resetbutton--disabled {
      opacity: 0.4;
      box-shadow: none !important; }
    .PPStripeDetails__resetbutton:focus {
      outline: none;
      box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1); }
    .PPStripeDetails__resetbutton:hover {
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2); }
    .PPStripeDetails__resetbutton:focus {
      background-color: #b9b9b9; }
  .PPStripeDetails__closebutton {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid transparent;
    color: #03854a;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    margin: 6px;
    min-width: 64px;
    padding: 0 16px;
    position: relative;
    text-align: center;
    transition: all 250ms ease-in-out;
    background-color: #d2d2d2;
    color: #fff; }
    .PPStripeDetails__closebutton:focus {
      outline: none; }
    .PPStripeDetails__closebutton:hover {
      cursor: pointer; }
    .PPStripeDetails__closebutton:disabled, .PPStripeDetails__closebutton--disabled {
      opacity: 0.4;
      box-shadow: none !important; }
    .PPStripeDetails__closebutton:focus {
      outline: none;
      box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1); }
    .PPStripeDetails__closebutton:hover {
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2); }
    .PPStripeDetails__closebutton:focus {
      background-color: #b9b9b9; }
  .PPStripeDetails__submitbutton {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid transparent;
    color: #03854a;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    margin: 6px;
    min-width: 64px;
    padding: 0 16px;
    position: relative;
    text-align: center;
    transition: all 250ms ease-in-out;
    background-color: #03854a;
    color: #fff; }
    .PPStripeDetails__submitbutton:focus {
      outline: none; }
    .PPStripeDetails__submitbutton:hover {
      cursor: pointer; }
    .PPStripeDetails__submitbutton:disabled, .PPStripeDetails__submitbutton--disabled {
      opacity: 0.4;
      box-shadow: none !important; }
    .PPStripeDetails__submitbutton:focus {
      outline: none;
      box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1); }
    .PPStripeDetails__submitbutton:hover {
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2); }
    .PPStripeDetails__submitbutton:focus {
      background-color: #02532e; }
  .PPStripeDetails__deletebutton {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid transparent;
    color: #03854a;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    margin: 6px;
    min-width: 64px;
    padding: 0 16px;
    position: relative;
    text-align: center;
    transition: all 250ms ease-in-out;
    background-color: #c03705;
    color: #fff; }
    .PPStripeDetails__deletebutton:focus {
      outline: none; }
    .PPStripeDetails__deletebutton:hover {
      cursor: pointer; }
    .PPStripeDetails__deletebutton:disabled, .PPStripeDetails__deletebutton--disabled {
      opacity: 0.4;
      box-shadow: none !important; }
    .PPStripeDetails__deletebutton:focus {
      outline: none;
      box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1); }
    .PPStripeDetails__deletebutton:hover {
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2); }
    .PPStripeDetails__deletebutton:focus {
      background-color: #8e2904; }
  .PPStripeDetails__content {
    padding: 0 32px 32px; }
  .PPStripeDetails .Stripe__defaultCurrency .Select {
    width: 200px; }
  .PPStripeDetails .LegalEntity__type .Select {
    width: 300px; }
  .PPStripeDetails .LegalEntity__gender .Select {
    width: 300px; }
  .PPStripeDetails .DateOfBirth__day .Select {
    width: 300px; }
  .PPStripeDetails .DateOfBirth__month .Select {
    width: 300px; }
  .PPStripeDetails .DateOfBirth__year .Select {
    width: 300px; }
  .PPStripeDetails .Address__country .Select {
    width: 300px; }
  .PPStripeDetails__header-padding-top {
    padding-top: 70px; }
  .PPStripeDetails__notification {
    text-align: center;
    margin-bottom: 32px; }
    .PPStripeDetails__notification-title {
      color: rgba(0, 0, 0, 0.87);
      font-size: 20px;
      font-weight: 500; }
    .PPStripeDetails__notification-subtitle {
      color: rgba(0, 0, 0, 0.87);
      font-size: 15px;
      font-weight: 400;
      font-style: italic; }
    .PPStripeDetails__notification-list {
      display: inline-block;
      list-style: none;
      margin: 0;
      padding: 0; }
      .PPStripeDetails__notification-list-item {
        color: rgba(0, 0, 0, 0.87);
        font-size: 13px;
        font-weight: 500; }
  .PPStripeDetails__external-accounts__header, .PPStripeDetails__external-accounts-header {
    color: rgba(0, 0, 0, 0.87);
    font-size: 20px;
    font-weight: 500;
    color: #727272;
    font-weight: 300;
    margin: 0 -32px 24px;
    border-bottom: 1px solid #dfdfdf;
    padding: 0 32px 8px;
    padding-top: 16px; }
  .PPStripeDetails__external-accounts-header {
    display: flex;
    flex-direction: row; }
  .PPStripeDetails__external-accounts-title {
    flex-grow: 1;
    line-height: 48px; }
  .PPStripeDetails__external-accounts-action {
    flex-grow: 1;
    text-align: right;
    margin-bottom: 16px; }
  .PPStripeDetails__external-accounts-createbutton {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid transparent;
    color: #03854a;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    margin: 6px;
    min-width: 64px;
    padding: 0 16px;
    position: relative;
    text-align: center;
    transition: all 250ms ease-in-out;
    background-color: #0dac64;
    color: #fff; }
    .PPStripeDetails__external-accounts-createbutton:focus {
      outline: none; }
    .PPStripeDetails__external-accounts-createbutton:hover {
      cursor: pointer; }
    .PPStripeDetails__external-accounts-createbutton:disabled, .PPStripeDetails__external-accounts-createbutton--disabled {
      opacity: 0.4;
      box-shadow: none !important; }
    .PPStripeDetails__external-accounts-createbutton:focus {
      background-color: #097d48; }
  .PPStripeDetails__external-accounts .DataList {
    border: 1px solid #dfdfdf;
    border-radius: 2px; }
    .PPStripeDetails__external-accounts .DataList__header-row {
      display: none; }
    .PPStripeDetails__external-accounts .DataList__footer-row {
      display: none; }
    .PPStripeDetails__external-accounts .DataList__body-row-cell {
      padding-top: 16px;
      padding-bottom: 16px; }
    .PPStripeDetails__external-accounts .DataList__body-row:hover {
      background-color: #fff;
      cursor: auto; }
    .PPStripeDetails__external-accounts .DataList__body-row-cell--action {
      text-align: right; }
