.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: #333;
  font-family: roboto;
  font-size: 14px; }

.Toastify__toast-theme--colored.Toastify__toast--default {
  font-family: roboto;
  font-size: 14px; }

.Toastify__toast-theme--colored.Toastify__toast--error {
  font-family: roboto;
  font-size: 14px; }

.Toastify__toast-theme--colored.Toastify__toast--info {
  font-family: roboto;
  font-size: 14px; }

.Toastify__toast-theme--colored.Toastify__toast--success {
  font-family: roboto;
  font-size: 14px; }

.ModelsPage {
  /* stylelint-disable selector-class-pattern */ }
  .ModelsPage__inner {
    margin: 0 auto;
    max-width: 1140px;
    padding: 0 24px; }
  .ModelsPage__header {
    margin: 24px 0; }
  .ModelsPage__title {
    margin: 0;
    position: relative; }
    .ModelsPage__title-name {
      display: inline-block; }
    .ModelsPage__title-id {
      color: rgba(0, 0, 0, 0.87);
      font-size: 15px;
      font-weight: 400; }
    .ModelsPage__title-status {
      color: rgba(0, 0, 0, 0.87);
      font-size: 12px;
      font-weight: 300;
      background-color: #0dac64;
      border-radius: 2px;
      color: #fff;
      display: inline-block;
      margin-left: 16px;
      padding: 0 8px;
      position: absolute; }
  .ModelsPage__subtitle {
    display: none; }
  .ModelsPage__content {
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12);
    padding: 24px; }
    .ModelsPage__content__header {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 16px 32px;
      z-index: 200; }
    .ModelsPage__content__actions {
      display: flex;
      flex: 1;
      justify-content: flex-end; }
      .ModelsPage__content__actions > *:first-child {
        margin-left: 0; }
    .ModelsPage__content__options {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end; }
      .ModelsPage__content__options > *:last-child {
        margin-left: 0; }
    .ModelsPage__content__features {
      display: flex;
      justify-content: flex-start; }
      .ModelsPage__content__features > *:first-child {
        margin-left: 0; }
    .ModelsPage__content__content {
      z-index: 100; }
  .ModelsPage__bottom-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
  .ModelsPage .Select {
    margin-left: 20px;
    min-width: 104px; }
    .ModelsPage .Select__control {
      border-radius: 2px;
      height: 36px;
      cursor: pointer; }
      .ModelsPage .Select__control--is-focused {
        border-color: #0dac64;
        box-shadow: none; }
        .ModelsPage .Select__control--is-focused:hover {
          border-color: #0dac64; }
    .ModelsPage .Select__option {
      box-sizing: border-box;
      background-color: #fff;
      color: rgba(0, 0, 0, 0.6);
      cursor: pointer;
      display: block;
      padding: 8px 10px; }
      .ModelsPage .Select__option--is-focused {
        background-color: rgba(0, 126, 255, 0.08);
        color: rgba(0, 0, 0, 0.87); }
    .ModelsPage .Select__indicators span {
      width: 0; }
