.RadioGroupField {
  margin-bottom: 12px;
  /* stylelint-disable selector-class-pattern */
  /* stylelint-enable selector-class-pattern */ }
  .RadioGroupField__title, .RadioGroupField-title {
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    font-weight: 300;
    margin: 1em 0 0.5em;
    line-height: 1.2em;
    padding: 0;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 400;
    margin-top: 1.25em;
    margin-bottom: 0.05em;
    display: block; }
    .RadioGroupField__title--locked, .RadioGroupField-title--locked {
      margin-bottom: 0; }
  .RadioGroupField__wrapper, .RadioGroupField-wrapper {
    display: block; }
  .RadioGroupField-subtitle, .RadioGroupField__subtitle {
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    font-weight: 300;
    margin: 1em 0 0.5em;
    line-height: 1.2em;
    padding: 0;
    font-size: 11px;
    color: rgba(0, 0, 0, 0.8);
    margin: 4px 0 0;
    opacity: 1;
    visibility: visible; }
    .RadioGroupField-subtitle--locked, .RadioGroupField__subtitle--locked {
      height: 0;
      opacity: 0;
      visibility: hidden; }
  .RadioGroupField-error, .RadioGroupField__error {
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    font-weight: 300;
    margin: 1em 0 0.5em;
    line-height: 1.2em;
    padding: 0;
    color: #c03705;
    font-size: 11px;
    font-weight: 400;
    margin: 0;
    opacity: 0;
    visibility: hidden; }
    .RadioGroupField-error--error, .RadioGroupField__error--error {
      opacity: 1;
      visibility: visible; }
    .RadioGroupField-error--locked, .RadioGroupField__error--locked {
      opacity: 0;
      visibility: hidden; }
  .RadioGroupField__element, .RadioGroupField-element {
    margin-bottom: 6px;
    margin-top: 6px;
    transition: all 250ms ease-in-out; }
    .RadioGroupField__element--locked, .RadioGroupField-element--locked {
      border: 0 none !important;
      color: rgba(0, 0, 0, 0.87) !important;
      height: 18px !important;
      margin-top: 0;
      padding: 0 !important;
      text-overflow: ellipsis;
      transition: all 100ms ease-in-out;
      white-space: nowrap;
      width: 100%; }
    .RadioGroupField__element--error, .RadioGroupField-element--error {
      border-color: #c03705 !important; }
  .RadioGroupField .Select {
    padding-bottom: 6px;
    padding-top: 6px; }
  .RadioGroupField .Select > .Select-control {
    border-radius: 2px; }
  .RadioGroupField .Select.is-focused > .Select-control {
    border-color: #0dac64;
    box-shadow: none; }
  .RadioGroupField--error .Select > .Select-control {
    border-color: #c03705 !important; }
  .RadioGroupField--locked .Select {
    margin: 0;
    top: -5px; }
  .RadioGroupField--locked .Select > .Select-control {
    background-color: transparent;
    border: 0 none;
    transition: all 250ms ease-in-out; }
  .RadioGroupField--locked .Select-arrow-zone {
    display: none;
    transition: all 250ms ease-in-out; }
  .RadioGroupField--locked .Select-placeholder {
    display: none;
    transition: all 250ms ease-in-out; }
  .RadioGroupField--locked .Select-value {
    padding-left: 0 !important;
    transition: all 250ms ease-in-out; }

.RadioGroupField__title {
  padding-bottom: 0.5em; }

.RadioGroupField__radio {
  margin-left: 0.5em; }
