.App {
  display: flex;
  flex-direction: row; }
  .App__navigation {
    background-color: white;
    height: 100%;
    position: fixed;
    top: 0;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.35);
    overflow-x: hidden;
    transition: all 0.15s ease-in-out;
    z-index: 200; }
    .App__navigation:not(.App__navigation--collapsed) {
      width: 240px; }
    .App__navigation--collapsed .App__navigation-collapse {
      background-image: url("../../images/material-icons/ic_chevron_right_black_24px.svg");
      background-position: center; }
    .App__navigation-collapse {
      bottom: 0;
      height: 48px;
      background-color: #eee;
      left: 0;
      position: absolute;
      right: 0;
      cursor: pointer;
      background-image: url("../../images/material-icons/ic_chevron_left_black_24px.svg");
      background-size: 24px;
      background-repeat: no-repeat;
      background-position: 200px center; }
  .App__content {
    flex-grow: 1;
    margin-left: 240px; }
    .App__content--collapsed {
      margin-left: 60px; }
  .App__padding-top {
    padding-top: 50px; }
