.ContractProvider__vatlookup {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px; }
  .ContractProvider__vatlookup-text {
    color: rgba(0, 0, 0, 0.87);
    font-size: 15px;
    font-weight: 400;
    margin-right: 12px;
    font-weight: 500; }
  .ContractProvider__vatlookup-button {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid transparent;
    color: #03854a;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    margin: 6px;
    min-width: 64px;
    padding: 0 16px;
    position: relative;
    text-align: center;
    transition: all 250ms ease-in-out;
    background-color: #0dac64;
    color: #fff; }
    .ContractProvider__vatlookup-button:focus {
      outline: none; }
    .ContractProvider__vatlookup-button:hover {
      cursor: pointer; }
    .ContractProvider__vatlookup-button:disabled, .ContractProvider__vatlookup-button--disabled {
      opacity: 0.4;
      box-shadow: none !important; }
    .ContractProvider__vatlookup-button:focus {
      background-color: #097d48; }

.ContractProvider__small-text {
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  font-weight: 300;
  font-style: italic;
  color: #ed7c31; }

.ContractProvider__webTitle {
  margin-top: -0.75em;
  padding-left: 3ch;
  margin-bottom: 0.75em; }
