.ContractTemplateOptions__header {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px; }

.ContractTemplateOptions__content {
  border: 1px solid #dfdfdf;
  border-radius: 2px; }

.ContractTemplateOptions__select {
  flex-basis: 60%; }

.ContractTemplateOptions__addbutton {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid transparent;
  color: #03854a;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  margin: 6px;
  min-width: 64px;
  padding: 0 16px;
  position: relative;
  text-align: center;
  transition: all 250ms ease-in-out;
  background-color: #0dac64;
  color: #fff; }
  .ContractTemplateOptions__addbutton:focus {
    outline: none; }
  .ContractTemplateOptions__addbutton:hover {
    cursor: pointer; }
  .ContractTemplateOptions__addbutton:disabled, .ContractTemplateOptions__addbutton--disabled {
    opacity: 0.4;
    box-shadow: none !important; }
  .ContractTemplateOptions__addbutton:focus {
    background-color: #097d48; }

.ContractTemplateOptions__removebutton {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid transparent;
  color: #03854a;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  margin: 6px;
  min-width: 64px;
  padding: 0 16px;
  position: relative;
  text-align: center;
  transition: all 250ms ease-in-out;
  background-color: #c03705;
  color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;
  min-width: 36px;
  width: 36px;
  background-image: url("../../../images/material-icons/ic_delete_white_24px.svg"); }
  .ContractTemplateOptions__removebutton:focus {
    outline: none; }
  .ContractTemplateOptions__removebutton:hover {
    cursor: pointer; }
  .ContractTemplateOptions__removebutton:disabled, .ContractTemplateOptions__removebutton--disabled {
    opacity: 0.4;
    box-shadow: none !important; }
  .ContractTemplateOptions__removebutton:focus {
    background-color: #8e2904; }

.ContractTemplateOptions__nodata {
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  font-weight: 400;
  height: 81px;
  line-height: 81px;
  text-align: center;
  color: #c03705;
  font-weight: 500; }

.ContractTemplateOptions .DataList__header-row {
  display: none; }

.ContractTemplateOptions .DataList__footer-row {
  display: none; }

.ContractTemplateOptions .DataList__body-row-cell {
  padding-top: 16px;
  padding-bottom: 16px; }

.ContractTemplateOptions .DataList__body-row:hover {
  background-color: #fff;
  cursor: auto; }

.ContractTemplateOptions .DataList__body-row-cell--action {
  text-align: right; }
