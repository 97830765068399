.DataList {
  border-collapse: collapse;
  border-spacing: 56px 0;
  width: 100%; }
  .DataList__header-row {
    border-bottom: 1px solid #dfdfdf; }
  .DataList__header-row-cell {
    padding-right: 56px;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    font-weight: 300;
    color: #727272;
    font-weight: 500;
    height: 56px; }
    .DataList__header-row-cell:first-child {
      padding-left: 32px;
      padding-right: 24px; }
    .DataList__header-row-cell:last-child {
      padding-right: 32px; }
    .DataList__header-row-cell--string {
      text-align: left; }
    .DataList__header-row-cell--number {
      text-align: right; }
    .DataList__header-row-cell--image {
      width: 32px; }
    .DataList__header-row-cell--csv ul {
      margin: 0;
      padding: 0;
      list-style: none; }
    .DataList__header-row-cell--csv li {
      width: 240px; }
  .DataList__body-row {
    border-top: 1px solid #dfdfdf;
    cursor: pointer; }
    .DataList__body-row:first-child {
      border-top: 0 none; }
    .DataList__body-row:hover {
      background-color: #7cddb1; }
    .DataList__body-row--selected {
      background-color: #7cddb1; }
  .DataList__body-row-cell {
    padding-right: 56px;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
    font-size: 13px;
    font-weight: 400;
    height: 48px; }
    .DataList__body-row-cell:first-child {
      padding-left: 32px;
      padding-right: 24px; }
    .DataList__body-row-cell:last-child {
      padding-right: 32px; }
    .DataList__body-row-cell--string {
      text-align: left; }
    .DataList__body-row-cell--number {
      text-align: right; }
    .DataList__body-row-cell--image {
      width: 32px; }
    .DataList__body-row-cell--csv ul {
      margin: 0;
      padding: 0;
      list-style: none; }
    .DataList__body-row-cell--csv li {
      width: 240px; }
    .DataList__body-row-cell--image .DataList__body-row-cell-inner {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      border: 1px solid #dfdfdf;
      border-radius: 100%;
      height: 32px;
      width: 32px; }
  .DataList__footer-row {
    border-top: 1px solid #dfdfdf; }
  .DataList__footer-row-cell {
    height: 56px; }
