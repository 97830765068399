.ActionButton {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid transparent;
  color: #03854a;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  margin: 6px;
  min-width: 64px;
  padding: 0 16px;
  position: relative;
  text-align: center;
  transition: all 250ms ease-in-out; }
  .ActionButton:focus {
    outline: none; }
  .ActionButton:hover {
    cursor: pointer; }
  .ActionButton:disabled, .ActionButton--disabled {
    opacity: 0.4;
    box-shadow: none !important; }
