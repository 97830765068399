.PPProviderList {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12);
  /* stylelint-disable selector-class-pattern */ }
  .PPProviderList__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 32px;
    z-index: 200; }
  .PPProviderList__actions {
    display: flex;
    flex: 1;
    justify-content: flex-end; }
    .PPProviderList__actions > *:first-child {
      margin-left: 0; }
  .PPProviderList__options {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end; }
    .PPProviderList__options > *:last-child {
      margin-left: 0; }
  .PPProviderList__features {
    display: flex;
    justify-content: flex-start; }
    .PPProviderList__features > *:first-child {
      margin-left: 0; }
  .PPProviderList__content {
    z-index: 100; }
  .PPProviderList__options {
    align-items: center; }
  .PPProviderList__displayOptionsRow {
    padding-top: 24px;
    display: inline-flex; }
  .PPProviderList__createbutton {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid transparent;
    color: #03854a;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    margin: 6px;
    min-width: 64px;
    padding: 0 16px;
    position: relative;
    text-align: center;
    transition: all 250ms ease-in-out;
    background-color: #0dac64;
    color: #fff; }
    .PPProviderList__createbutton:focus {
      outline: none; }
    .PPProviderList__createbutton:hover {
      cursor: pointer; }
    .PPProviderList__createbutton:disabled, .PPProviderList__createbutton--disabled {
      opacity: 0.4;
      box-shadow: none !important; }
    .PPProviderList__createbutton:focus {
      background-color: #097d48; }
  .PPProviderList__searchfield {
    background: #fff;
    border-radius: 2px;
    border: 1px solid #bfbfbf;
    height: 36px;
    padding: 8px;
    height: 38px; }
    .PPProviderList__searchfield:focus {
      outline: none;
      border-color: #0dac64; }
  .PPProviderList .Select {
    margin-left: 6px;
    margin-right: 6px;
    min-width: 72px; }
    .PPProviderList .Select__control {
      border-radius: 2px;
      height: 36px;
      cursor: pointer; }
      .PPProviderList .Select__control--is-focused {
        border-color: #0dac64;
        box-shadow: none; }
        .PPProviderList .Select__control--is-focused:hover {
          border-color: #0dac64; }
    .PPProviderList .Select__option {
      box-sizing: border-box;
      background-color: #fff;
      color: rgba(0, 0, 0, 0.6);
      cursor: pointer;
      display: block;
      padding: 8px 10px; }
      .PPProviderList .Select__option--is-focused {
        background-color: rgba(0, 126, 255, 0.08);
        color: rgba(0, 0, 0, 0.87); }
    .PPProviderList .Select__indicators span {
      width: 0; }
