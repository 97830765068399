.EnvironmentOverlay {
  align-items: flex-end;
  color: #fff;
  display: flex;
  height: 140px;
  justify-content: center;
  position: fixed;
  right: -70px;
  text-transform: uppercase;
  top: -70px;
  transform: rotate(45deg);
  width: 140px;
  z-index: 99999; }
  .EnvironmentOverlay--local {
    background: purple; }
  .EnvironmentOverlay--development {
    background: orange; }
  .EnvironmentOverlay--demo {
    background: green; }
  .EnvironmentOverlay--staging {
    background: green; }
  .EnvironmentOverlay__text {
    line-height: 3em;
    font-size: 10px;
    font-weight: 700; }
