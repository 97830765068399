.PasswordField {
  margin-bottom: 12px; }
  .PasswordField__title, .PasswordField-title {
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    font-weight: 300;
    margin: 1em 0 0.5em;
    line-height: 1.2em;
    padding: 0;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 400;
    margin-top: 1.25em;
    margin-bottom: 0.05em;
    display: block; }
    .PasswordField__title--locked, .PasswordField-title--locked {
      margin-bottom: 0; }
  .PasswordField__wrapper, .PasswordField-wrapper {
    display: block; }
  .PasswordField-subtitle, .PasswordField__subtitle {
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    font-weight: 300;
    margin: 1em 0 0.5em;
    line-height: 1.2em;
    padding: 0;
    font-size: 11px;
    color: rgba(0, 0, 0, 0.8);
    margin: 4px 0 0;
    opacity: 1;
    visibility: visible; }
    .PasswordField-subtitle--locked, .PasswordField__subtitle--locked {
      height: 0;
      opacity: 0;
      visibility: hidden; }
  .PasswordField-error, .PasswordField__error {
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    font-weight: 300;
    margin: 1em 0 0.5em;
    line-height: 1.2em;
    padding: 0;
    color: #c03705;
    font-size: 11px;
    font-weight: 400;
    margin: 0;
    opacity: 0;
    visibility: hidden; }
    .PasswordField-error--error, .PasswordField__error--error {
      opacity: 1;
      visibility: visible; }
    .PasswordField-error--locked, .PasswordField__error--locked {
      opacity: 0;
      visibility: hidden; }
  .PasswordField__element, .PasswordField-element {
    margin-bottom: 6px;
    margin-top: 6px;
    transition: all 250ms ease-in-out; }
    .PasswordField__element--locked, .PasswordField-element--locked {
      border: 0 none !important;
      color: rgba(0, 0, 0, 0.87) !important;
      height: 18px !important;
      margin-top: 0;
      padding: 0 !important;
      text-overflow: ellipsis;
      transition: all 100ms ease-in-out;
      white-space: nowrap;
      width: 100%; }
    .PasswordField__element--error, .PasswordField-element--error {
      border-color: #c03705 !important; }
  .PasswordField__element {
    background: #fff;
    border-radius: 2px;
    border: 1px solid #bfbfbf;
    height: 36px;
    padding: 8px; }
    .PasswordField__element:focus {
      outline: none;
      border-color: #0dac64; }
