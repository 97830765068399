.StringField {
  margin-bottom: 12px; }
  .StringField__title, .StringField-title {
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    font-weight: 300;
    margin: 1em 0 0.5em;
    line-height: 1.2em;
    padding: 0;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 400;
    margin-top: 1.25em;
    margin-bottom: 0.05em;
    display: block; }
    .StringField__title--locked, .StringField-title--locked {
      margin-bottom: 0; }
  .StringField__wrapper, .StringField-wrapper {
    display: block; }
  .StringField-subtitle, .StringField__subtitle {
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    font-weight: 300;
    margin: 1em 0 0.5em;
    line-height: 1.2em;
    padding: 0;
    font-size: 11px;
    color: rgba(0, 0, 0, 0.8);
    margin: 4px 0 0;
    opacity: 1;
    visibility: visible; }
    .StringField-subtitle--locked, .StringField__subtitle--locked {
      height: 0;
      opacity: 0;
      visibility: hidden; }
  .StringField-error, .StringField__error {
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    font-weight: 300;
    margin: 1em 0 0.5em;
    line-height: 1.2em;
    padding: 0;
    color: #c03705;
    font-size: 11px;
    font-weight: 400;
    margin: 0;
    opacity: 0;
    visibility: hidden; }
    .StringField-error--error, .StringField__error--error {
      opacity: 1;
      visibility: visible; }
    .StringField-error--locked, .StringField__error--locked {
      opacity: 0;
      visibility: hidden; }
  .StringField__element, .StringField-element {
    margin-bottom: 6px;
    margin-top: 6px;
    transition: all 250ms ease-in-out; }
    .StringField__element--locked, .StringField-element--locked {
      border: 0 none !important;
      color: rgba(0, 0, 0, 0.87) !important;
      height: 18px !important;
      margin-top: 0;
      padding: 0 !important;
      text-overflow: ellipsis;
      transition: all 100ms ease-in-out;
      white-space: nowrap;
      width: 100%; }
    .StringField__element--error, .StringField-element--error {
      border-color: #c03705 !important; }
  .StringField__element {
    background: #fff;
    border-radius: 2px;
    border: 1px solid #bfbfbf;
    height: 36px;
    padding: 8px; }
    .StringField__element:focus {
      outline: none;
      border-color: #0dac64; }

.conditions_input:focus {
  outline: none;
  border-color: #0dac64; }

.conditions_input__title,
.conditions_input-title {
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  font-weight: 300;
  margin: 1em 0 0.5em;
  line-height: 1.2em;
  padding: 0;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 400;
  margin-top: 1.25em;
  margin-bottom: 0.05em;
  display: block; }

.conditions_input__element {
  margin-bottom: 6px;
  margin-top: 6px;
  transition: all 250ms ease-in-out; }

.conditions_input {
  border-color: #bfbfbf;
  padding: 10px; }
