/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

html {
  box-sizing: border-box;
  height: 100%; }

body {
  min-height: 100%; }

html,
body {
  margin: 0;
  padding: 0; }

*,
*::before,
*::after {
  box-sizing: inherit; }

#root {
  display: flex;
  flex-direction: column; }

/* stylelint-disable */
/* stylelint-enable */
@font-face {
  src: url("../font/roboto/Regular/Roboto-Regular.woff2?v=2.137") format("woff2"), url("../font/roboto/Regular/Roboto-Regular.woff?v=2.137") format("woff");
  font-family: Roboto;
  font-style: normal;
  font-weight: 400; }

/* stylelint-disable */
/* stylelint-enable */
@font-face {
  src: url("../font/roboto/Thin/Roboto-Thin.woff2?v=2.137") format("woff2"), url("../font/roboto/Thin/Roboto-Thin.woff?v=2.137") format("woff");
  font-family: Roboto;
  font-style: normal;
  font-weight: 100; }

/* stylelint-disable */
/* stylelint-enable */
@font-face {
  src: url("../font/roboto/Italic/Roboto-Italic.woff2?v=2.137") format("woff2"), url("../font/roboto/Italic/Roboto-Italic.woff?v=2.137") format("woff");
  font-family: Roboto;
  font-style: italic;
  font-weight: 400; }

/* stylelint-disable */
/* stylelint-enable */
@font-face {
  src: url("../font/roboto/Light/Roboto-Light.woff2?v=2.137") format("woff2"), url("../font/roboto/Light/Roboto-Light.woff?v=2.137") format("woff");
  font-family: Roboto;
  font-style: normal;
  font-weight: 300; }

/* stylelint-disable */
/* stylelint-enable */
@font-face {
  src: url("../font/roboto/Bold/Roboto-Bold.woff2?v=2.137") format("woff2"), url("../font/roboto/Bold/Roboto-Bold.woff?v=2.137") format("woff");
  font-family: Roboto;
  font-style: normal;
  font-weight: 700; }

/* stylelint-disable */
/* stylelint-enable */
@font-face {
  src: url("../font/roboto/Black/Roboto-Black.woff2?v=2.137") format("woff2"), url("../font/roboto/Black/Roboto-Black.woff?v=2.137") format("woff");
  font-family: Roboto;
  font-style: normal;
  font-weight: 900; }

/* stylelint-disable */
/* stylelint-enable */
@font-face {
  src: url("../font/roboto/ThinItalic/Roboto-ThinItalic.woff2?v=2.137") format("woff2"), url("../font/roboto/ThinItalic/Roboto-ThinItalic.woff?v=2.137") format("woff");
  font-family: Roboto;
  font-style: italic;
  font-weight: 100; }

/* stylelint-disable */
/* stylelint-enable */
@font-face {
  src: url("../font/roboto/LightItalic/Roboto-LightItalic.woff2?v=2.137") format("woff2"), url("../font/roboto/LightItalic/Roboto-LightItalic.woff?v=2.137") format("woff");
  font-family: Roboto;
  font-style: italic;
  font-weight: 300; }

/* stylelint-disable */
/* stylelint-enable */
@font-face {
  src: url("../font/roboto/Medium/Roboto-Medium.woff2?v=2.137") format("woff2"), url("../font/roboto/Medium/Roboto-Medium.woff?v=2.137") format("woff");
  font-family: Roboto;
  font-style: normal;
  font-weight: 500; }

/* stylelint-disable */
/* stylelint-enable */
@font-face {
  src: url("../font/roboto/MediumItalic/Roboto-MediumItalic.woff2?v=2.137") format("woff2"), url("../font/roboto/MediumItalic/Roboto-MediumItalic.woff?v=2.137") format("woff");
  font-family: Roboto;
  font-style: italic;
  font-weight: 500; }

/* stylelint-disable */
/* stylelint-enable */
@font-face {
  src: url("../font/roboto/BoldItalic/Roboto-BoldItalic.woff2?v=2.137") format("woff2"), url("../font/roboto/BoldItalic/Roboto-BoldItalic.woff?v=2.137") format("woff");
  font-family: Roboto;
  font-style: italic;
  font-weight: 700; }

/* stylelint-disable */
/* stylelint-enable */
@font-face {
  src: url("../font/roboto/BlackItalic/Roboto-BlackItalic.woff2?v=2.137") format("woff2"), url("../font/roboto/BlackItalic/Roboto-BlackItalic.woff?v=2.137") format("woff");
  font-family: Roboto;
  font-style: italic;
  font-weight: 900; }

html,
body {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 1.7144em; }

h1 {
  color: rgba(0, 0, 0, 0.87);
  font-size: 24px;
  font-weight: 400;
  line-height: 1.2em;
  margin: 1em 0 0.5em;
  font-size: 24px;
  padding: 0; }

h2 {
  color: rgba(0, 0, 0, 0.87);
  font-size: 15px;
  font-weight: 400;
  margin: 1em 0 0.5em;
  line-height: 1.2em;
  padding: 0;
  font-size: 20px; }

h3 {
  color: rgba(0, 0, 0, 0.87);
  font-size: 20px;
  font-weight: 500;
  margin: 1em 0 0.5em;
  line-height: 1.2em;
  padding: 0;
  font-size: 16px; }

h4 {
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  font-weight: 300;
  margin: 1em 0 0.5em;
  line-height: 1.2em;
  padding: 0; }

h5 {
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  font-weight: 300;
  margin: 1em 0 0.5em;
  line-height: 1.2em;
  padding: 0; }

h6 {
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  font-weight: 300;
  margin: 1em 0 0.5em;
  line-height: 1.2em;
  padding: 0; }

p {
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  font-weight: 400;
  margin: 1em 0 1.84em;
  padding: 0; }

html {
  background-color: transparent; }

body {
  background-color: #eee; }
