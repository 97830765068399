.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: #333;
  font-family: roboto;
  font-size: 14px; }

.Toastify__toast-theme--colored.Toastify__toast--default {
  font-family: roboto;
  font-size: 14px; }

.Toastify__toast-theme--colored.Toastify__toast--error {
  font-family: roboto;
  font-size: 14px; }

.Toastify__toast-theme--colored.Toastify__toast--info {
  font-family: roboto;
  font-size: 14px; }

.Toastify__toast-theme--colored.Toastify__toast--success {
  font-family: roboto;
  font-size: 14px; }

.ReleaseNotes__inner {
  margin: 0 auto;
  padding: 0 24px; }

.ReleaseNotes__header {
  margin: 24px 0; }

.ReleaseNotes__title {
  margin: 0;
  position: relative; }

.ReleaseNotes__release-header {
  display: flex;
  justify-content: left;
  align-items: center; }
  .ReleaseNotes__release-header h3 {
    color: rgba(0, 0, 0, 0.87);
    font-size: 15px;
    font-weight: 400;
    margin: 1em 0 0.5em;
    line-height: 1.2em;
    padding: 0;
    font-size: 20px;
    margin-left: 16px;
    font-weight: 700; }

.ReleaseNotes__release-version {
  color: rgba(0, 0, 0, 0.87);
  font-size: 20px;
  font-weight: 500;
  margin: 1em 0 0.5em;
  line-height: 1.2em;
  padding: 0;
  font-size: 16px;
  border-radius: 4px;
  padding: 4px;
  color: #e1f2f1;
  font-weight: 600;
  text-align: center;
  min-width: 80px;
  padding: 8px;
  background-color: #008b7d; }

.ReleaseNotes__release-list {
  list-style-type: none;
  margin-top: 4px;
  margin-left: 56px; }
  .ReleaseNotes__release-list li {
    display: flex;
    flex-direction: row;
    align-items: baseline; }
  .ReleaseNotes__release-list h5 {
    color: rgba(0, 0, 0, 0.87);
    font-size: 13px;
    font-weight: 400;
    margin: 1em 0 1.84em;
    padding: 0;
    margin: 0;
    margin-left: 8px; }

.ReleaseNotes__release-tag__ADDED {
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  font-weight: 300;
  margin: 1em 0 0.5em;
  line-height: 1.2em;
  padding: 0;
  border-radius: 4px;
  padding: 4px;
  color: #e1f2f1;
  font-weight: 600;
  text-align: center;
  min-width: 70px;
  background-color: #0dac64; }

.ReleaseNotes__release-tag__FIXED {
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  font-weight: 300;
  margin: 1em 0 0.5em;
  line-height: 1.2em;
  padding: 0;
  border-radius: 4px;
  padding: 4px;
  color: #e1f2f1;
  font-weight: 600;
  text-align: center;
  min-width: 70px;
  background-color: #f95113; }

.ReleaseNotes__release-tag__NEW {
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  font-weight: 300;
  margin: 1em 0 0.5em;
  line-height: 1.2em;
  padding: 0;
  border-radius: 4px;
  padding: 4px;
  color: #e1f2f1;
  font-weight: 600;
  text-align: center;
  min-width: 70px;
  background-color: #5392c0; }

.ReleaseNotes__release-tag__REMOVED {
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  font-weight: 300;
  margin: 1em 0 0.5em;
  line-height: 1.2em;
  padding: 0;
  border-radius: 4px;
  padding: 4px;
  color: #e1f2f1;
  font-weight: 600;
  text-align: center;
  min-width: 70px;
  background-color: #c03705; }

.ReleaseNotes__release-tag__UPDATED {
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  font-weight: 300;
  margin: 1em 0 0.5em;
  line-height: 1.2em;
  padding: 0;
  border-radius: 4px;
  padding: 4px;
  color: #e1f2f1;
  font-weight: 600;
  text-align: center;
  min-width: 70px;
  background-color: #03854a; }

.ReleaseNotes__release-tag__IMPROVED {
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  font-weight: 300;
  margin: 1em 0 0.5em;
  line-height: 1.2em;
  padding: 0;
  border-radius: 4px;
  padding: 4px;
  color: #e1f2f1;
  font-weight: 600;
  text-align: center;
  min-width: 70px;
  background-color: #0a4d7b; }
