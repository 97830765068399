.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: #333;
  font-family: roboto;
  font-size: 14px; }

.Toastify__toast-theme--colored.Toastify__toast--default {
  font-family: roboto;
  font-size: 14px; }

.Toastify__toast-theme--colored.Toastify__toast--error {
  font-family: roboto;
  font-size: 14px; }

.Toastify__toast-theme--colored.Toastify__toast--info {
  font-family: roboto;
  font-size: 14px; }

.Toastify__toast-theme--colored.Toastify__toast--success {
  font-family: roboto;
  font-size: 14px; }

.Translations__header {
  margin: 24px 0; }

.Translations__title {
  margin: 0;
  position: relative; }
  .Translations__title-name {
    display: inline-block; }

.Translations__createbutton {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid transparent;
  color: #03854a;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  margin: 6px;
  min-width: 64px;
  padding: 0 16px;
  position: relative;
  text-align: center;
  transition: all 250ms ease-in-out;
  background-color: #03854a;
  color: #fff;
  margin-left: 0; }
  .Translations__createbutton:focus {
    outline: none; }
  .Translations__createbutton:hover {
    cursor: pointer; }
  .Translations__createbutton:disabled, .Translations__createbutton--disabled {
    opacity: 0.4;
    box-shadow: none !important; }
  .Translations__createbutton:focus {
    outline: none;
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1); }
  .Translations__createbutton:hover {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2); }
  .Translations__createbutton:focus {
    background-color: #02532e; }

.Translations__checkbutton {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid transparent;
  color: #03854a;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  margin: 6px;
  min-width: 64px;
  padding: 0 16px;
  position: relative;
  text-align: center;
  transition: all 250ms ease-in-out;
  background-color: #0a4d7b;
  color: #fff; }
  .Translations__checkbutton:focus {
    outline: none; }
  .Translations__checkbutton:hover {
    cursor: pointer; }
  .Translations__checkbutton:disabled, .Translations__checkbutton--disabled {
    opacity: 0.4;
    box-shadow: none !important; }
  .Translations__checkbutton:focus {
    background-color: #062f4c; }

.Translations__backbutton {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid transparent;
  color: #03854a;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  margin: 6px;
  min-width: 64px;
  padding: 0 16px;
  position: relative;
  text-align: center;
  transition: all 250ms ease-in-out;
  background-color: #0a4d7b;
  color: #fff;
  margin-left: 0; }
  .Translations__backbutton:focus {
    outline: none; }
  .Translations__backbutton:hover {
    cursor: pointer; }
  .Translations__backbutton:disabled, .Translations__backbutton--disabled {
    opacity: 0.4;
    box-shadow: none !important; }
  .Translations__backbutton:focus {
    background-color: #062f4c; }

.Translations__publishbutton {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid transparent;
  color: #03854a;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  margin: 6px;
  min-width: 64px;
  padding: 0 16px;
  position: relative;
  text-align: center;
  transition: all 250ms ease-in-out;
  background-color: #c03705;
  color: #fff; }
  .Translations__publishbutton:focus {
    outline: none; }
  .Translations__publishbutton:hover {
    cursor: pointer; }
  .Translations__publishbutton:disabled, .Translations__publishbutton--disabled {
    opacity: 0.4;
    box-shadow: none !important; }
  .Translations__publishbutton:focus {
    outline: none;
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1); }
  .Translations__publishbutton:hover {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2); }
  .Translations__publishbutton:focus {
    background-color: #8e2904; }

.Translations__submitbutton {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid transparent;
  color: #03854a;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  margin: 6px;
  min-width: 64px;
  padding: 0 16px;
  position: relative;
  text-align: center;
  transition: all 250ms ease-in-out;
  background-color: #03854a;
  color: #fff; }
  .Translations__submitbutton:focus {
    outline: none; }
  .Translations__submitbutton:hover {
    cursor: pointer; }
  .Translations__submitbutton:disabled, .Translations__submitbutton--disabled {
    opacity: 0.4;
    box-shadow: none !important; }
  .Translations__submitbutton:focus {
    outline: none;
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1); }
  .Translations__submitbutton:hover {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2); }
  .Translations__submitbutton:focus {
    background-color: #02532e; }

.Translations__resetbutton {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid transparent;
  color: #03854a;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  margin: 6px;
  min-width: 64px;
  padding: 0 16px;
  position: relative;
  text-align: center;
  transition: all 250ms ease-in-out;
  background-color: #d2d2d2;
  color: #fff;
  margin-left: 0; }
  .Translations__resetbutton:focus {
    outline: none; }
  .Translations__resetbutton:hover {
    cursor: pointer; }
  .Translations__resetbutton:disabled, .Translations__resetbutton--disabled {
    opacity: 0.4;
    box-shadow: none !important; }
  .Translations__resetbutton:focus {
    outline: none;
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1); }
  .Translations__resetbutton:hover {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2); }
  .Translations__resetbutton:focus {
    background-color: #b9b9b9; }

.Translations__search {
  margin-top: 2vh;
  margin-bottom: 2vh; }

.Translations__searchbutton {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid transparent;
  color: #03854a;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  margin: 6px;
  min-width: 64px;
  padding: 0 16px;
  position: relative;
  text-align: center;
  transition: all 250ms ease-in-out;
  background-color: #0a4d7b;
  color: #fff; }
  .Translations__searchbutton:focus {
    outline: none; }
  .Translations__searchbutton:hover {
    cursor: pointer; }
  .Translations__searchbutton:disabled, .Translations__searchbutton--disabled {
    opacity: 0.4;
    box-shadow: none !important; }
  .Translations__searchbutton:focus {
    background-color: #062f4c; }

.Translations__searchfield {
  display: inline-block;
  margin-bottom: 0; }

.Translations__search-options {
  margin-top: -8px;
  padding-left: 8px; }

.Translations__applications {
  display: inline-block; }

.Translations__list-active {
  display: block; }

.Translations__list-hidden {
  display: none; }

.Translations__importbutton {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid transparent;
  color: #03854a;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  margin: 6px;
  min-width: 64px;
  padding: 0 16px;
  position: relative;
  text-align: center;
  transition: all 250ms ease-in-out;
  background-color: #03854a;
  color: #fff;
  font-weight: normal;
  margin-left: 0; }
  .Translations__importbutton:focus {
    outline: none; }
  .Translations__importbutton:hover {
    cursor: pointer; }
  .Translations__importbutton:disabled, .Translations__importbutton--disabled {
    opacity: 0.4;
    box-shadow: none !important; }
  .Translations__importbutton:focus {
    background-color: #02532e; }

.Translations__exportbutton {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid transparent;
  color: #03854a;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  margin: 6px;
  min-width: 64px;
  padding: 0 16px;
  position: relative;
  text-align: center;
  transition: all 250ms ease-in-out;
  background-color: #03854a;
  color: #fff;
  margin-left: 0; }
  .Translations__exportbutton:focus {
    outline: none; }
  .Translations__exportbutton:hover {
    cursor: pointer; }
  .Translations__exportbutton:disabled, .Translations__exportbutton--disabled {
    opacity: 0.4;
    box-shadow: none !important; }
  .Translations__exportbutton:focus {
    background-color: #02532e; }

.Translations__import-locale-select {
  width: 150px; }

.Translations__disabled {
  opacity: 0.4;
  box-shadow: none !important; }

.Translations__import-export-container {
  float: right; }

.Translations__import-title {
  display: block; }

.Translations__deletebutton {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid transparent;
  color: #03854a;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  margin: 6px;
  min-width: 64px;
  padding: 0 16px;
  position: relative;
  text-align: center;
  transition: all 250ms ease-in-out;
  background-color: #c03705;
  color: #fff; }
  .Translations__deletebutton:focus {
    outline: none; }
  .Translations__deletebutton:hover {
    cursor: pointer; }
  .Translations__deletebutton:disabled, .Translations__deletebutton--disabled {
    opacity: 0.4;
    box-shadow: none !important; }
  .Translations__deletebutton:focus {
    outline: none;
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1); }
  .Translations__deletebutton:hover {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2); }
  .Translations__deletebutton:focus {
    background-color: #8e2904; }

.TranslationApplication {
  display: inline-block;
  margin: 6px;
  min-width: 80px; }

.TranslationValues___inner {
  margin: 0 auto;
  padding: 0 24px; }

.TranslationValue___inner {
  margin: 0 auto;
  padding: 0 24px; }

.TranslationValue__submitbutton {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid transparent;
  color: #03854a;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  margin: 6px;
  min-width: 64px;
  padding: 0 16px;
  position: relative;
  text-align: center;
  transition: all 250ms ease-in-out;
  background-color: #03854a;
  color: #fff;
  margin: 0;
  min-width: 222px; }
  .TranslationValue__submitbutton:focus {
    outline: none; }
  .TranslationValue__submitbutton:hover {
    cursor: pointer; }
  .TranslationValue__submitbutton:disabled, .TranslationValue__submitbutton--disabled {
    opacity: 0.4;
    box-shadow: none !important; }
  .TranslationValue__submitbutton:focus {
    outline: none;
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1); }
  .TranslationValue__submitbutton:hover {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2); }
  .TranslationValue__submitbutton:focus {
    background-color: #02532e; }
