.PPNavigation__menu {
  margin-bottom: 16px; }
  .PPNavigation__menu-item {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid transparent;
    color: #03854a;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    margin: 6px;
    min-width: 64px;
    padding: 0 16px;
    position: relative;
    text-align: center;
    transition: all 250ms ease-in-out;
    border-radius: 0;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
    margin-right: 32px;
    padding-left: 0;
    padding-right: 0;
    transition: none; }
    .PPNavigation__menu-item:focus {
      outline: none; }
    .PPNavigation__menu-item:hover {
      cursor: pointer; }
    .PPNavigation__menu-item:disabled, .PPNavigation__menu-item--disabled {
      opacity: 0.4;
      box-shadow: none !important; }
    .PPNavigation__menu-item--active {
      border-left: 0;
      border-right: 0;
      border-bottom: 2px solid #2eb779;
      font-weight: 700; }
    .PPNavigation__menu-item:focus {
      color: #727272;
      font-weight: 700; }
    .PPNavigation__menu-item:first-child {
      margin-left: 0; }

.PPNavigation__breadcrumb {
  margin: 16px 0; }
  .PPNavigation__breadcrumb-item {
    color: #727272;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 11px; }
    .PPNavigation__breadcrumb-item::after {
      content: '>';
      margin: 0 8px; }
    .PPNavigation__breadcrumb-item:focus {
      text-decoration: underline; }
  .PPNavigation__breadcrumb-item:last-child::after {
    content: '';
    margin: 0; }
