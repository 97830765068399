.ReleaseVersions__downarrow {
  display: inline-block;
  background-size: cover;
  height: 16px;
  width: 16px;
  background-image: url("../../../images/material-icons/down-arrow.svg"); }

.ReleaseVersions__buttonwrapper {
  width: 100%;
  display: flex;
  justify-content: center; }

.ReleaseVersions__buttoncontent {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.ReleaseVersions__loadbutton {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid transparent;
  color: #03854a;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  margin: 6px;
  min-width: 64px;
  padding: 0 16px;
  position: relative;
  text-align: center;
  transition: all 250ms ease-in-out;
  background-color: #225759;
  color: #fff;
  width: 144px; }
  .ReleaseVersions__loadbutton:focus {
    outline: none; }
  .ReleaseVersions__loadbutton:hover {
    cursor: pointer; }
  .ReleaseVersions__loadbutton:disabled, .ReleaseVersions__loadbutton--disabled {
    opacity: 0.4;
    box-shadow: none !important; }
  .ReleaseVersions__loadbutton:focus {
    background-color: #143334; }
