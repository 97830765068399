.PPPrices {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12); }
  .PPPrices__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 32px;
    z-index: 200; }
  .PPPrices__actions {
    display: flex;
    flex: 1;
    justify-content: flex-end; }
    .PPPrices__actions > *:first-child {
      margin-left: 0; }
  .PPPrices__options {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end; }
    .PPPrices__options > *:last-child {
      margin-left: 0; }
  .PPPrices__features {
    display: flex;
    justify-content: flex-start; }
    .PPPrices__features > *:first-child {
      margin-left: 0; }
  .PPPrices__content {
    z-index: 100; }
  .PPPrices__createbutton {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid transparent;
    color: #03854a;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    margin: 6px;
    min-width: 64px;
    padding: 0 16px;
    position: relative;
    text-align: center;
    transition: all 250ms ease-in-out;
    background-color: #0dac64;
    color: #fff; }
    .PPPrices__createbutton:focus {
      outline: none; }
    .PPPrices__createbutton:hover {
      cursor: pointer; }
    .PPPrices__createbutton:disabled, .PPPrices__createbutton--disabled {
      opacity: 0.4;
      box-shadow: none !important; }
    .PPPrices__createbutton:focus {
      background-color: #097d48; }

.TitleAndButtonsContainer {
  max-width: 38em; }
  .TitleAndButtonsContainer .ButtonsContainer {
    padding-top: 0.5em;
    padding-bottom: 1.5em; }
  .TitleAndButtonsContainer h3 {
    font-size: 16px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.6); }

.ProgressBar__procentage {
  padding: 5px 2px; }

.ProgressBar__indeterminate {
  margin-top: 18px; }
