.ConnectProvider__icon {
  margin-right: 0.125vw; }

.ConnectProvider__text_v4_dealer_is_connected {
  color: #0dac64;
  font-weight: bolder; }

.ConnectProvider__text_no_v4_dealer_connected {
  color: #ffac41;
  font-weight: bolder;
  font-style: italic; }

.ConnectProvider__connect_provider_add_to_v4_button {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid transparent;
  color: #03854a;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  margin: 6px;
  min-width: 64px;
  padding: 0 16px;
  position: relative;
  text-align: center;
  transition: all 250ms ease-in-out;
  background-color: #0dac64;
  color: #fff; }
  .ConnectProvider__connect_provider_add_to_v4_button:focus {
    outline: none; }
  .ConnectProvider__connect_provider_add_to_v4_button:hover {
    cursor: pointer; }
  .ConnectProvider__connect_provider_add_to_v4_button:disabled, .ConnectProvider__connect_provider_add_to_v4_button--disabled {
    opacity: 0.4;
    box-shadow: none !important; }
  .ConnectProvider__connect_provider_add_to_v4_button:focus {
    background-color: #097d48; }

.ConnectProvider__connect_provider_v4_edit_button {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid transparent;
  color: #03854a;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  margin: 6px;
  min-width: 64px;
  padding: 0 16px;
  position: relative;
  text-align: center;
  transition: all 250ms ease-in-out;
  background-color: #0dac64;
  color: #fff;
  background-color: #ffac41; }
  .ConnectProvider__connect_provider_v4_edit_button:focus {
    outline: none; }
  .ConnectProvider__connect_provider_v4_edit_button:hover {
    cursor: pointer; }
  .ConnectProvider__connect_provider_v4_edit_button:disabled, .ConnectProvider__connect_provider_v4_edit_button--disabled {
    opacity: 0.4;
    box-shadow: none !important; }
  .ConnectProvider__connect_provider_v4_edit_button:focus {
    background-color: #097d48; }
  .ConnectProvider__connect_provider_v4_edit_button:focus {
    background-color: #ffac41; }

.ConnectProvider__is_loading input {
  cursor: wait !important; }

.ConnectProvider__dialog_container .MuiPaper-root-101 {
  overflow-y: clip;
  width: auto !important;
  min-width: auto !important; }

.ConnectProvider__dialog_content_container {
  width: 100%;
  height: 100%; }

.ConnectProvider__dialog_sortable_table_container {
  overflow-y: 'scroll';
  height: auto;
  width: auto;
  min-width: fit-content; }

.ConnectProvider__dialog_sortable_table {
  overflow-y: 'scroll';
  height: 50vh;
  width: fit-content; }

.ConnectProvider__button_in_dialog {
  height: 2.5vh; }

.ConnectProvider__button_container {
  display: flex; }

.ConnectProvider__add_provider_to_v4_button {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid transparent;
  color: #03854a;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  margin: 6px;
  min-width: 64px;
  padding: 0 16px;
  position: relative;
  text-align: center;
  transition: all 250ms ease-in-out;
  background-color: #0dac64;
  color: #fff;
  width: 100%;
  background-color: #ffac41; }
  .ConnectProvider__add_provider_to_v4_button:focus {
    outline: none; }
  .ConnectProvider__add_provider_to_v4_button:hover {
    cursor: pointer; }
  .ConnectProvider__add_provider_to_v4_button:disabled, .ConnectProvider__add_provider_to_v4_button--disabled {
    opacity: 0.4;
    box-shadow: none !important; }
  .ConnectProvider__add_provider_to_v4_button:focus {
    background-color: #097d48; }
  .ConnectProvider__add_provider_to_v4_button:focus {
    background-color: #ffac41; }

.ConnectProvider__disconnect_provider_v4_button {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid transparent;
  color: #03854a;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  margin: 6px;
  min-width: 64px;
  padding: 0 16px;
  position: relative;
  text-align: center;
  transition: all 250ms ease-in-out;
  background-color: #0dac64;
  color: #fff;
  background-color: #c03705; }
  .ConnectProvider__disconnect_provider_v4_button:focus {
    outline: none; }
  .ConnectProvider__disconnect_provider_v4_button:hover {
    cursor: pointer; }
  .ConnectProvider__disconnect_provider_v4_button:disabled, .ConnectProvider__disconnect_provider_v4_button--disabled {
    opacity: 0.4;
    box-shadow: none !important; }
  .ConnectProvider__disconnect_provider_v4_button:focus {
    background-color: #097d48; }
  .ConnectProvider__disconnect_provider_v4_button:focus {
    background-color: #c03705; }

.ConnectProvider__dialog_connect_button {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid transparent;
  color: #03854a;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  margin: 6px;
  min-width: 64px;
  padding: 0 16px;
  position: relative;
  text-align: center;
  transition: all 250ms ease-in-out;
  background-color: #0dac64;
  color: #fff; }
  .ConnectProvider__dialog_connect_button:focus {
    outline: none; }
  .ConnectProvider__dialog_connect_button:hover {
    cursor: pointer; }
  .ConnectProvider__dialog_connect_button:disabled, .ConnectProvider__dialog_connect_button--disabled {
    opacity: 0.4;
    box-shadow: none !important; }
  .ConnectProvider__dialog_connect_button:focus {
    background-color: #097d48; }

.ConnectProvider__dialog_search_container {
  display: inline-flex; }

.ConnectProvider__dialog_search_button {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid transparent;
  color: #03854a;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  margin: 6px;
  min-width: 64px;
  padding: 0 16px;
  position: relative;
  text-align: center;
  transition: all 250ms ease-in-out; }
  .ConnectProvider__dialog_search_button:focus {
    outline: none; }
  .ConnectProvider__dialog_search_button:hover {
    cursor: pointer; }
  .ConnectProvider__dialog_search_button:disabled, .ConnectProvider__dialog_search_button--disabled {
    opacity: 0.4;
    box-shadow: none !important; }

.ConnectProvider__dialog_search_button_container, .ConnectProvider__dialog_clear_button_container {
  margin-top: 4px;
  margin-left: 8px; }

.ConnectProvider__dialog_part_paper {
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 16px 32px;
  font-size: medium !important; }

.ConnectProvider__dialog_part_subpaper {
  padding: 2px 16px; }
