.SelectField {
  margin-bottom: 12px;
  /* stylelint-disable selector-class-pattern */
  /* stylelint-enable selector-class-pattern */ }
  .SelectField__title, .SelectField-title {
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    font-weight: 300;
    margin: 1em 0 0.5em;
    line-height: 1.2em;
    padding: 0;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 400;
    margin-top: 1.25em;
    margin-bottom: 0.05em;
    display: block; }
    .SelectField__title--locked, .SelectField-title--locked {
      margin-bottom: 0; }
  .SelectField__wrapper, .SelectField-wrapper {
    display: block; }
  .SelectField-subtitle, .SelectField__subtitle {
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    font-weight: 300;
    margin: 1em 0 0.5em;
    line-height: 1.2em;
    padding: 0;
    font-size: 11px;
    color: rgba(0, 0, 0, 0.8);
    margin: 4px 0 0;
    opacity: 1;
    visibility: visible; }
    .SelectField-subtitle--locked, .SelectField__subtitle--locked {
      height: 0;
      opacity: 0;
      visibility: hidden; }
  .SelectField-error, .SelectField__error {
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    font-weight: 300;
    margin: 1em 0 0.5em;
    line-height: 1.2em;
    padding: 0;
    color: #c03705;
    font-size: 11px;
    font-weight: 400;
    margin: 0;
    opacity: 0;
    visibility: hidden; }
    .SelectField-error--error, .SelectField__error--error {
      opacity: 1;
      visibility: visible; }
    .SelectField-error--locked, .SelectField__error--locked {
      opacity: 0;
      visibility: hidden; }
  .SelectField__element, .SelectField-element {
    margin-bottom: 6px;
    margin-top: 6px;
    transition: all 250ms ease-in-out; }
    .SelectField__element--locked, .SelectField-element--locked {
      border: 0 none !important;
      color: rgba(0, 0, 0, 0.87) !important;
      height: 18px !important;
      margin-top: 0;
      padding: 0 !important;
      text-overflow: ellipsis;
      transition: all 100ms ease-in-out;
      white-space: nowrap;
      width: 100%; }
    .SelectField__element--error, .SelectField-element--error {
      border-color: #c03705 !important; }
  .SelectField .Select {
    padding-bottom: 6px;
    padding-top: 6px; }
  .SelectField .Select__control {
    border-radius: 2px; }
  .SelectField .Select__control--is-focused {
    border-color: #0dac64;
    box-shadow: none; }
    .SelectField .Select__control--is-focused:hover {
      border-color: #0dac64; }
  .SelectField .Select__menu-list {
    padding: 0; }
  .SelectField .Select__option {
    box-sizing: border-box;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
    display: block;
    padding: 8px 10px; }
  .SelectField .Select__option--is-selected {
    background-color: rgba(0, 126, 255, 0.04);
    color: rgba(0, 0, 0, 0.87); }
  .SelectField .Select__option--is-focused {
    background-color: rgba(0, 126, 255, 0.08);
    color: rgba(0, 0, 0, 0.87); }
  .SelectField__error .Select__control {
    border-color: #c03705 !important; }
  .SelectField .Select--is-disabled .Select {
    margin: 0;
    top: -5px; }
  .SelectField .Select--is-disabled .Select__control {
    background-color: transparent;
    border: 0 none;
    transition: all 250ms ease-in-out; }
  .SelectField .Select--is-disabled .Select__indicators {
    display: none;
    transition: all 250ms ease-in-out; }
  .SelectField .Select--is-disabled .Select__placeholder {
    display: none;
    transition: all 250ms ease-in-out; }
  .SelectField .Select--is-disabled .Select__value-container {
    padding-left: 0 !important;
    transition: all 250ms ease-in-out; }
  .SelectField .Select--is-disabled .Select__single-value {
    margin-left: 0 !important;
    transition: all 250ms ease-in-out; }
