.PPTemplateDetails {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12); }
  .PPTemplateDetails__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 32px;
    z-index: 200; }
  .PPTemplateDetails__actions {
    display: flex;
    flex: 1;
    justify-content: flex-end; }
    .PPTemplateDetails__actions > *:first-child {
      margin-left: 0; }
  .PPTemplateDetails__options {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end; }
    .PPTemplateDetails__options > *:last-child {
      margin-left: 0; }
  .PPTemplateDetails__features {
    display: flex;
    justify-content: flex-start; }
    .PPTemplateDetails__features > *:first-child {
      margin-left: 0; }
  .PPTemplateDetails__content {
    z-index: 100; }
  .PPTemplateDetails__header {
    position: sticky;
    top: 0;
    background-color: #fff; }
    .PPTemplateDetails__header--sticky {
      border-bottom: 1px solid #dfdfdf; }
  .PPTemplateDetails__title {
    line-height: 48px;
    margin: 0; }
  .PPTemplateDetails__info {
    flex-grow: 1; }
  .PPTemplateDetails__editbutton {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid transparent;
    color: #03854a;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    margin: 6px;
    min-width: 64px;
    padding: 0 16px;
    position: relative;
    text-align: center;
    transition: all 250ms ease-in-out;
    background-color: #0dac64;
    color: #fff; }
    .PPTemplateDetails__editbutton:focus {
      outline: none; }
    .PPTemplateDetails__editbutton:hover {
      cursor: pointer; }
    .PPTemplateDetails__editbutton:disabled, .PPTemplateDetails__editbutton--disabled {
      opacity: 0.4;
      box-shadow: none !important; }
    .PPTemplateDetails__editbutton:focus {
      outline: none;
      box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1); }
    .PPTemplateDetails__editbutton:hover {
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2); }
    .PPTemplateDetails__editbutton:focus {
      background-color: #097d48; }
  .PPTemplateDetails__inactivatebutton {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid transparent;
    color: #03854a;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    margin: 6px;
    min-width: 64px;
    padding: 0 16px;
    position: relative;
    text-align: center;
    transition: all 250ms ease-in-out;
    background-color: #f99513;
    color: #fff; }
    .PPTemplateDetails__inactivatebutton:focus {
      outline: none; }
    .PPTemplateDetails__inactivatebutton:hover {
      cursor: pointer; }
    .PPTemplateDetails__inactivatebutton:disabled, .PPTemplateDetails__inactivatebutton--disabled {
      opacity: 0.4;
      box-shadow: none !important; }
    .PPTemplateDetails__inactivatebutton:focus {
      outline: none;
      box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1); }
    .PPTemplateDetails__inactivatebutton:hover {
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2); }
    .PPTemplateDetails__inactivatebutton:focus {
      background-color: #d47a05; }
  .PPTemplateDetails__activatebutton {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid transparent;
    color: #03854a;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    margin: 6px;
    min-width: 64px;
    padding: 0 16px;
    position: relative;
    text-align: center;
    transition: all 250ms ease-in-out;
    background-color: #1566a0;
    color: #fff; }
    .PPTemplateDetails__activatebutton:focus {
      outline: none; }
    .PPTemplateDetails__activatebutton:hover {
      cursor: pointer; }
    .PPTemplateDetails__activatebutton:disabled, .PPTemplateDetails__activatebutton--disabled {
      opacity: 0.4;
      box-shadow: none !important; }
    .PPTemplateDetails__activatebutton:focus {
      outline: none;
      box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1); }
    .PPTemplateDetails__activatebutton:hover {
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2); }
    .PPTemplateDetails__activatebutton:focus {
      background-color: #0f4973; }
  .PPTemplateDetails__cancelbutton {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid transparent;
    color: #03854a;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    margin: 6px;
    min-width: 64px;
    padding: 0 16px;
    position: relative;
    text-align: center;
    transition: all 250ms ease-in-out;
    background-color: #d2d2d2;
    color: #fff; }
    .PPTemplateDetails__cancelbutton:focus {
      outline: none; }
    .PPTemplateDetails__cancelbutton:hover {
      cursor: pointer; }
    .PPTemplateDetails__cancelbutton:disabled, .PPTemplateDetails__cancelbutton--disabled {
      opacity: 0.4;
      box-shadow: none !important; }
    .PPTemplateDetails__cancelbutton:focus {
      outline: none;
      box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1); }
    .PPTemplateDetails__cancelbutton:hover {
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2); }
    .PPTemplateDetails__cancelbutton:focus {
      background-color: #b9b9b9; }
  .PPTemplateDetails__resetbutton {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid transparent;
    color: #03854a;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    margin: 6px;
    min-width: 64px;
    padding: 0 16px;
    position: relative;
    text-align: center;
    transition: all 250ms ease-in-out;
    background-color: #d2d2d2;
    color: #fff; }
    .PPTemplateDetails__resetbutton:focus {
      outline: none; }
    .PPTemplateDetails__resetbutton:hover {
      cursor: pointer; }
    .PPTemplateDetails__resetbutton:disabled, .PPTemplateDetails__resetbutton--disabled {
      opacity: 0.4;
      box-shadow: none !important; }
    .PPTemplateDetails__resetbutton:focus {
      outline: none;
      box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1); }
    .PPTemplateDetails__resetbutton:hover {
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2); }
    .PPTemplateDetails__resetbutton:focus {
      background-color: #b9b9b9; }
  .PPTemplateDetails__closebutton {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid transparent;
    color: #03854a;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    margin: 6px;
    min-width: 64px;
    padding: 0 16px;
    position: relative;
    text-align: center;
    transition: all 250ms ease-in-out;
    background-color: #d2d2d2;
    color: #fff; }
    .PPTemplateDetails__closebutton:focus {
      outline: none; }
    .PPTemplateDetails__closebutton:hover {
      cursor: pointer; }
    .PPTemplateDetails__closebutton:disabled, .PPTemplateDetails__closebutton--disabled {
      opacity: 0.4;
      box-shadow: none !important; }
    .PPTemplateDetails__closebutton:focus {
      outline: none;
      box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1); }
    .PPTemplateDetails__closebutton:hover {
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2); }
    .PPTemplateDetails__closebutton:focus {
      background-color: #b9b9b9; }
  .PPTemplateDetails__submitbutton {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid transparent;
    color: #03854a;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    margin: 6px;
    min-width: 64px;
    padding: 0 16px;
    position: relative;
    text-align: center;
    transition: all 250ms ease-in-out;
    background-color: #03854a;
    color: #fff; }
    .PPTemplateDetails__submitbutton:focus {
      outline: none; }
    .PPTemplateDetails__submitbutton:hover {
      cursor: pointer; }
    .PPTemplateDetails__submitbutton:disabled, .PPTemplateDetails__submitbutton--disabled {
      opacity: 0.4;
      box-shadow: none !important; }
    .PPTemplateDetails__submitbutton:focus {
      outline: none;
      box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1); }
    .PPTemplateDetails__submitbutton:hover {
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2); }
    .PPTemplateDetails__submitbutton:focus {
      background-color: #02532e; }
  .PPTemplateDetails__deletebutton {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid transparent;
    color: #03854a;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    margin: 6px;
    min-width: 64px;
    padding: 0 16px;
    position: relative;
    text-align: center;
    transition: all 250ms ease-in-out;
    background-color: #c03705;
    color: #fff; }
    .PPTemplateDetails__deletebutton:focus {
      outline: none; }
    .PPTemplateDetails__deletebutton:hover {
      cursor: pointer; }
    .PPTemplateDetails__deletebutton:disabled, .PPTemplateDetails__deletebutton--disabled {
      opacity: 0.4;
      box-shadow: none !important; }
    .PPTemplateDetails__deletebutton:focus {
      outline: none;
      box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1); }
    .PPTemplateDetails__deletebutton:hover {
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2); }
    .PPTemplateDetails__deletebutton:focus {
      background-color: #8e2904; }
  .PPTemplateDetails__content {
    padding: 0 32px 32px; }
  .PPTemplateDetails .PriceSpecification__currency {
    width: 120px; }
  .PPTemplateDetails .ContractTemplate__calculationMethod {
    width: 120px; }
  .PPTemplateDetails .ContractTemplate__priceSource {
    width: 120px; }
  .PPTemplateDetails__header-padding-top {
    padding-top: 70px; }
