.PPTemplateList {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12); }
  .PPTemplateList__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 32px;
    z-index: 200; }
  .PPTemplateList__actions {
    display: flex;
    flex: 1;
    justify-content: flex-end; }
    .PPTemplateList__actions > *:first-child {
      margin-left: 0; }
  .PPTemplateList__options {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end; }
    .PPTemplateList__options > *:last-child {
      margin-left: 0; }
  .PPTemplateList__features {
    display: flex;
    justify-content: flex-start; }
    .PPTemplateList__features > *:first-child {
      margin-left: 0; }
  .PPTemplateList__content {
    z-index: 100; }
  .PPTemplateList__createbutton, .PPTemplateList__clonebutton {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid transparent;
    color: #03854a;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    margin: 6px;
    min-width: 64px;
    padding: 0 16px;
    position: relative;
    text-align: center;
    transition: all 250ms ease-in-out;
    background-color: #0dac64;
    color: #fff; }
    .PPTemplateList__createbutton:focus, .PPTemplateList__clonebutton:focus {
      outline: none; }
    .PPTemplateList__createbutton:hover, .PPTemplateList__clonebutton:hover {
      cursor: pointer; }
    .PPTemplateList__createbutton:disabled, .PPTemplateList__createbutton--disabled, .PPTemplateList__clonebutton:disabled, .PPTemplateList__clonebutton--disabled {
      opacity: 0.4;
      box-shadow: none !important; }
    .PPTemplateList__createbutton:focus, .PPTemplateList__clonebutton:focus {
      background-color: #097d48; }

.PPTemplateList__header_sub {
  margin-left: 32px;
  margin-top: 16px;
  margin-bottom: 16px; }

.PPTemplateList__warning_icon {
  background-image: url("../../../images/material-icons/ic_warning_amber_24px.svg");
  background-repeat: no-repeat;
  background-size: auto;
  width: 24px;
  height: 24px;
  display: inline-block; }

.PPTemplateList__warning_text {
  padding-left: 8px;
  vertical-align: super; }
