.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: #333;
  font-family: roboto;
  font-size: 14px; }

.Toastify__toast-theme--colored.Toastify__toast--default {
  font-family: roboto;
  font-size: 14px; }

.Toastify__toast-theme--colored.Toastify__toast--error {
  font-family: roboto;
  font-size: 14px; }

.Toastify__toast-theme--colored.Toastify__toast--info {
  font-family: roboto;
  font-size: 14px; }

.Toastify__toast-theme--colored.Toastify__toast--success {
  font-family: roboto;
  font-size: 14px; }

.AccessKeys__inner {
  margin: 0 auto;
  padding: 0 24px; }

.AccessKeys__header {
  margin: 24px 0; }

.AccessKeys__title {
  margin: 0;
  position: relative; }
  .AccessKeys__title-name {
    display: inline-block; }

.AccessKeys__returnbutton {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid transparent;
  color: #03854a;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  margin: 6px;
  min-width: 64px;
  padding: 0 16px;
  position: relative;
  text-align: center;
  transition: all 250ms ease-in-out;
  background-color: #1566a0;
  color: #fff;
  text-decoration: none;
  margin-left: 0; }
  .AccessKeys__returnbutton:focus {
    outline: none; }
  .AccessKeys__returnbutton:hover {
    cursor: pointer; }
  .AccessKeys__returnbutton:disabled, .AccessKeys__returnbutton--disabled {
    opacity: 0.4;
    box-shadow: none !important; }
  .AccessKeys__returnbutton:focus {
    background-color: #0f4973; }

.AccessKeys__createbutton {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid transparent;
  color: #03854a;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  margin: 6px;
  min-width: 64px;
  padding: 0 16px;
  position: relative;
  text-align: center;
  transition: all 250ms ease-in-out;
  background-color: #03854a;
  color: #fff;
  margin-left: 0;
  margin-top: 20px; }
  .AccessKeys__createbutton:focus {
    outline: none; }
  .AccessKeys__createbutton:hover {
    cursor: pointer; }
  .AccessKeys__createbutton:disabled, .AccessKeys__createbutton--disabled {
    opacity: 0.4;
    box-shadow: none !important; }
  .AccessKeys__createbutton:focus {
    outline: none;
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1); }
  .AccessKeys__createbutton:hover {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2); }
  .AccessKeys__createbutton:focus {
    background-color: #02532e; }

.AccessKeys__editbutton {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid transparent;
  color: #03854a;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  margin: 6px;
  min-width: 64px;
  padding: 0 16px;
  position: relative;
  text-align: center;
  transition: all 250ms ease-in-out;
  background-color: #03854a;
  color: #fff;
  margin-left: 0; }
  .AccessKeys__editbutton:focus {
    outline: none; }
  .AccessKeys__editbutton:hover {
    cursor: pointer; }
  .AccessKeys__editbutton:disabled, .AccessKeys__editbutton--disabled {
    opacity: 0.4;
    box-shadow: none !important; }
  .AccessKeys__editbutton:focus {
    outline: none;
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1); }
  .AccessKeys__editbutton:hover {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2); }
  .AccessKeys__editbutton:focus {
    background-color: #02532e; }

.AccessKeys__deletebutton {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid transparent;
  color: #03854a;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  margin: 6px;
  min-width: 64px;
  padding: 0 16px;
  position: relative;
  text-align: center;
  transition: all 250ms ease-in-out;
  background-color: #c03705;
  color: #fff; }
  .AccessKeys__deletebutton:focus {
    outline: none; }
  .AccessKeys__deletebutton:hover {
    cursor: pointer; }
  .AccessKeys__deletebutton:disabled, .AccessKeys__deletebutton--disabled {
    opacity: 0.4;
    box-shadow: none !important; }
  .AccessKeys__deletebutton:focus {
    outline: none;
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1); }
  .AccessKeys__deletebutton:hover {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2); }
  .AccessKeys__deletebutton:focus {
    background-color: #8e2904; }

.AccessKeys__submitbutton {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid transparent;
  color: #03854a;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  margin: 6px;
  min-width: 64px;
  padding: 0 16px;
  position: relative;
  text-align: center;
  transition: all 250ms ease-in-out;
  background-color: #03854a;
  color: #fff; }
  .AccessKeys__submitbutton:focus {
    outline: none; }
  .AccessKeys__submitbutton:hover {
    cursor: pointer; }
  .AccessKeys__submitbutton:disabled, .AccessKeys__submitbutton--disabled {
    opacity: 0.4;
    box-shadow: none !important; }
  .AccessKeys__submitbutton:focus {
    outline: none;
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1); }
  .AccessKeys__submitbutton:hover {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2); }
  .AccessKeys__submitbutton:focus {
    background-color: #02532e; }

.AccessKeys__regenbutton {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid transparent;
  color: #03854a;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  margin: 6px;
  min-width: 64px;
  padding: 0 16px;
  position: relative;
  text-align: center;
  transition: all 250ms ease-in-out;
  background-color: #f99513;
  color: #fff;
  margin-left: 0; }
  .AccessKeys__regenbutton:focus {
    outline: none; }
  .AccessKeys__regenbutton:hover {
    cursor: pointer; }
  .AccessKeys__regenbutton:disabled, .AccessKeys__regenbutton--disabled {
    opacity: 0.4;
    box-shadow: none !important; }
  .AccessKeys__regenbutton:focus {
    outline: none;
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1); }
  .AccessKeys__regenbutton:hover {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2); }
  .AccessKeys__regenbutton:focus {
    background-color: #d47a05; }

.AccessKeys__resetbutton {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid transparent;
  color: #03854a;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  margin: 6px;
  min-width: 64px;
  padding: 0 16px;
  position: relative;
  text-align: center;
  transition: all 250ms ease-in-out;
  background-color: #d2d2d2;
  color: #fff;
  margin-left: 0;
  background-color: rgba(0, 0, 0, 0.65);
  color: #fff; }
  .AccessKeys__resetbutton:focus {
    outline: none; }
  .AccessKeys__resetbutton:hover {
    cursor: pointer; }
  .AccessKeys__resetbutton:disabled, .AccessKeys__resetbutton--disabled {
    opacity: 0.4;
    box-shadow: none !important; }
  .AccessKeys__resetbutton:focus {
    outline: none;
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1); }
  .AccessKeys__resetbutton:hover {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2); }
  .AccessKeys__resetbutton:focus {
    background-color: #b9b9b9; }
