.PPUserList {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12); }
  .PPUserList__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 32px;
    z-index: 200; }
  .PPUserList__actions {
    display: flex;
    flex: 1;
    justify-content: flex-end; }
    .PPUserList__actions > *:first-child {
      margin-left: 0; }
  .PPUserList__options {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end; }
    .PPUserList__options > *:last-child {
      margin-left: 0; }
  .PPUserList__features {
    display: flex;
    justify-content: flex-start; }
    .PPUserList__features > *:first-child {
      margin-left: 0; }
  .PPUserList__content {
    z-index: 100; }
  .PPUserList__createbutton {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid transparent;
    color: #03854a;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    margin: 6px;
    min-width: 64px;
    padding: 0 16px;
    position: relative;
    text-align: center;
    transition: all 250ms ease-in-out;
    background-color: #0dac64;
    color: #fff; }
    .PPUserList__createbutton:focus {
      outline: none; }
    .PPUserList__createbutton:hover {
      cursor: pointer; }
    .PPUserList__createbutton:disabled, .PPUserList__createbutton--disabled {
      opacity: 0.4;
      box-shadow: none !important; }
    .PPUserList__createbutton:focus {
      background-color: #097d48; }
