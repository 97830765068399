.TextareaField {
  margin-bottom: 12px; }
  .TextareaField__title, .TextareaField-title {
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    font-weight: 300;
    margin: 1em 0 0.5em;
    line-height: 1.2em;
    padding: 0;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 400;
    margin-top: 1.25em;
    margin-bottom: 0.05em;
    display: block; }
    .TextareaField__title--locked, .TextareaField-title--locked {
      margin-bottom: 0; }
  .TextareaField__wrapper, .TextareaField-wrapper {
    display: block; }
  .TextareaField-subtitle, .TextareaField__subtitle {
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    font-weight: 300;
    margin: 1em 0 0.5em;
    line-height: 1.2em;
    padding: 0;
    font-size: 11px;
    color: rgba(0, 0, 0, 0.8);
    margin: 4px 0 0;
    opacity: 1;
    visibility: visible; }
    .TextareaField-subtitle--locked, .TextareaField__subtitle--locked {
      height: 0;
      opacity: 0;
      visibility: hidden; }
  .TextareaField-error, .TextareaField__error {
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    font-weight: 300;
    margin: 1em 0 0.5em;
    line-height: 1.2em;
    padding: 0;
    color: #c03705;
    font-size: 11px;
    font-weight: 400;
    margin: 0;
    opacity: 0;
    visibility: hidden; }
    .TextareaField-error--error, .TextareaField__error--error {
      opacity: 1;
      visibility: visible; }
    .TextareaField-error--locked, .TextareaField__error--locked {
      opacity: 0;
      visibility: hidden; }
  .TextareaField__element, .TextareaField-element {
    margin-bottom: 6px;
    margin-top: 6px;
    transition: all 250ms ease-in-out; }
    .TextareaField__element--locked, .TextareaField-element--locked {
      border: 0 none !important;
      color: rgba(0, 0, 0, 0.87) !important;
      height: 18px !important;
      margin-top: 0;
      padding: 0 !important;
      text-overflow: ellipsis;
      transition: all 100ms ease-in-out;
      white-space: nowrap;
      width: 100%; }
    .TextareaField__element--error, .TextareaField-element--error {
      border-color: #c03705 !important; }
  .TextareaField__element {
    height: 80px; }
