.TransactionOverlay {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 150ms ease-in-out;
  opacity: 0;
  visibility: hidden;
  z-index: 9999; }
  .TransactionOverlay--show {
    opacity: 1;
    visibility: visible; }
  .TransactionOverlay__background {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: all 150ms ease-in-out;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: -1; }
  .TransactionOverlay__animation {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: all 150ms ease-in-out; }
