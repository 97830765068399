.FileUploadField {
  margin-bottom: 12px; }
  .FileUploadField__title, .FileUploadField-title {
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    font-weight: 300;
    margin: 1em 0 0.5em;
    line-height: 1.2em;
    padding: 0;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 400;
    margin-top: 1.25em;
    margin-bottom: 0.05em;
    display: block; }
    .FileUploadField__title--locked, .FileUploadField-title--locked {
      margin-bottom: 0; }
  .FileUploadField__wrapper, .FileUploadField-wrapper {
    display: block; }
  .FileUploadField-subtitle, .FileUploadField__subtitle {
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    font-weight: 300;
    margin: 1em 0 0.5em;
    line-height: 1.2em;
    padding: 0;
    font-size: 11px;
    color: rgba(0, 0, 0, 0.8);
    margin: 4px 0 0;
    opacity: 1;
    visibility: visible; }
    .FileUploadField-subtitle--locked, .FileUploadField__subtitle--locked {
      height: 0;
      opacity: 0;
      visibility: hidden; }
  .FileUploadField-error, .FileUploadField__error {
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    font-weight: 300;
    margin: 1em 0 0.5em;
    line-height: 1.2em;
    padding: 0;
    color: #c03705;
    font-size: 11px;
    font-weight: 400;
    margin: 0;
    opacity: 0;
    visibility: hidden; }
    .FileUploadField-error--error, .FileUploadField__error--error {
      opacity: 1;
      visibility: visible; }
    .FileUploadField-error--locked, .FileUploadField__error--locked {
      opacity: 0;
      visibility: hidden; }
  .FileUploadField__element, .FileUploadField-element {
    margin-bottom: 6px;
    margin-top: 6px;
    transition: all 250ms ease-in-out; }
    .FileUploadField__element--locked, .FileUploadField-element--locked {
      border: 0 none !important;
      color: rgba(0, 0, 0, 0.87) !important;
      height: 18px !important;
      margin-top: 0;
      padding: 0 !important;
      text-overflow: ellipsis;
      transition: all 100ms ease-in-out;
      white-space: nowrap;
      width: 100%; }
    .FileUploadField__element--error, .FileUploadField-element--error {
      border-color: #c03705 !important; }
  .FileUploadField .UploadField {
    display: none; }
  .FileUploadField--locked .FileUploadField__actions {
    display: none; }
  .FileUploadField__actions {
    display: inline-block; }
  .FileUploadField__element {
    background: #fff;
    border-radius: 2px;
    border: 1px solid #bfbfbf;
    height: 36px;
    padding: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0 none; }
    .FileUploadField__element:focus {
      outline: none;
      border-color: #0dac64; }
  .FileUploadField__uploadbutton {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid transparent;
    color: #03854a;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    margin: 6px;
    min-width: 64px;
    padding: 0 16px;
    position: relative;
    text-align: center;
    transition: all 250ms ease-in-out;
    background-color: #0dac64;
    color: #fff;
    padding-left: 40px !important;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: 0;
    top: 1px; }
    .FileUploadField__uploadbutton:focus {
      outline: none; }
    .FileUploadField__uploadbutton:hover {
      cursor: pointer; }
    .FileUploadField__uploadbutton:disabled, .FileUploadField__uploadbutton--disabled {
      opacity: 0.4;
      box-shadow: none !important; }
    .FileUploadField__uploadbutton:focus {
      background-color: #097d48; }
    .FileUploadField__uploadbutton::before {
      background-position: center;
      background-repeat: no-repeat;
      background-size: 24px;
      content: '';
      height: 36px;
      position: absolute;
      left: 10px;
      width: 24px;
      z-index: 100; }
    .FileUploadField__uploadbutton::before {
      background-image: url("../../../images/material-icons/ic_file_upload_black_24px.svg"); }
    .FileUploadField__uploadbutton--loading {
      padding-left: 40px !important;
      background-size: 18px; }
      .FileUploadField__uploadbutton--loading::before {
        background-position: center;
        background-repeat: no-repeat;
        background-size: 24px;
        content: '';
        height: 36px;
        position: absolute;
        left: 10px;
        width: 24px;
        z-index: 100; }
      .FileUploadField__uploadbutton--loading::before {
        background-image: url("../../../images/spinners/loader-green.gif"); }
  .FileUploadField__deletebutton {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid transparent;
    color: #03854a;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    margin: 6px;
    min-width: 64px;
    padding: 0 16px;
    position: relative;
    text-align: center;
    transition: all 250ms ease-in-out;
    background-color: #c03705;
    color: #fff; }
    .FileUploadField__deletebutton:focus {
      outline: none; }
    .FileUploadField__deletebutton:hover {
      cursor: pointer; }
    .FileUploadField__deletebutton:disabled, .FileUploadField__deletebutton--disabled {
      opacity: 0.4;
      box-shadow: none !important; }
    .FileUploadField__deletebutton:focus {
      background-color: #8e2904; }

.small {
  font-size: 12px; }

.logo-image {
  max-width: 300px; }
