.DateField {
  margin-bottom: 12px; }
  .DateField__title, .DateField-title {
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    font-weight: 300;
    margin: 1em 0 0.5em;
    line-height: 1.2em;
    padding: 0;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 400;
    margin-top: 1.25em;
    margin-bottom: 0.05em;
    display: block; }
    .DateField__title--locked, .DateField-title--locked {
      margin-bottom: 0; }
  .DateField__wrapper, .DateField-wrapper {
    display: block; }
  .DateField-subtitle, .DateField__subtitle {
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    font-weight: 300;
    margin: 1em 0 0.5em;
    line-height: 1.2em;
    padding: 0;
    font-size: 11px;
    color: rgba(0, 0, 0, 0.8);
    margin: 4px 0 0;
    opacity: 1;
    visibility: visible; }
    .DateField-subtitle--locked, .DateField__subtitle--locked {
      height: 0;
      opacity: 0;
      visibility: hidden; }
  .DateField-error, .DateField__error {
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    font-weight: 300;
    margin: 1em 0 0.5em;
    line-height: 1.2em;
    padding: 0;
    color: #c03705;
    font-size: 11px;
    font-weight: 400;
    margin: 0;
    opacity: 0;
    visibility: hidden; }
    .DateField-error--error, .DateField__error--error {
      opacity: 1;
      visibility: visible; }
    .DateField-error--locked, .DateField__error--locked {
      opacity: 0;
      visibility: hidden; }
  .DateField__element, .DateField-element {
    margin-bottom: 6px;
    margin-top: 6px;
    transition: all 250ms ease-in-out; }
    .DateField__element--locked, .DateField-element--locked {
      border: 0 none !important;
      color: rgba(0, 0, 0, 0.87) !important;
      height: 18px !important;
      margin-top: 0;
      padding: 0 !important;
      text-overflow: ellipsis;
      transition: all 100ms ease-in-out;
      white-space: nowrap;
      width: 100%; }
    .DateField__element--error, .DateField-element--error {
      border-color: #c03705 !important; }
  .DateField__element {
    background: #fff;
    border-radius: 2px;
    border: 1px solid #bfbfbf;
    height: 36px;
    padding: 8px; }
    .DateField__element:focus {
      outline: none;
      border-color: #0dac64; }
