.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: #333;
  font-family: roboto;
  font-size: 14px; }

.Toastify__toast-theme--colored.Toastify__toast--default {
  font-family: roboto;
  font-size: 14px; }

.Toastify__toast-theme--colored.Toastify__toast--error {
  font-family: roboto;
  font-size: 14px; }

.Toastify__toast-theme--colored.Toastify__toast--info {
  font-family: roboto;
  font-size: 14px; }

.Toastify__toast-theme--colored.Toastify__toast--success {
  font-family: roboto;
  font-size: 14px; }

.ProviderPage__inner {
  margin: 0 auto;
  padding: 0 24px; }

.ProviderPage__header {
  margin: 24px 0; }

.ProviderPage__inactive {
  background-color: #b8b8b8; }

.ProviderPage__title {
  margin: 0;
  position: relative; }
  .ProviderPage__title-name {
    display: inline-block; }
  .ProviderPage__title-id {
    color: rgba(0, 0, 0, 0.87);
    font-size: 15px;
    font-weight: 400; }
  .ProviderPage__title-status {
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    font-weight: 300;
    background-color: #0dac64;
    border-radius: 2px;
    color: #fff;
    display: inline-block;
    margin-left: 16px;
    padding: 0 8px;
    position: absolute; }
    .ProviderPage__title-status-inactive {
      color: rgba(0, 0, 0, 0.87);
      font-size: 12px;
      font-weight: 300;
      z-index: 1;
      background-color: #f95113;
      border-radius: 2px;
      color: #fff;
      display: inline-block;
      margin-left: 16px;
      padding: 0 8px;
      position: absolute; }

.ProviderPage__subtitle {
  display: none; }
