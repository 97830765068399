.NetworkErrorOverlay {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 150ms ease-in-out;
  opacity: 0;
  visibility: hidden;
  z-index: 9999; }
  .NetworkErrorOverlay--show {
    opacity: 1;
    visibility: visible; }
  .NetworkErrorOverlay__background {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: all 150ms ease-in-out;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: -1; }
  .NetworkErrorOverlay__panel {
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12);
    width: 500px;
    padding: 48px 48px 32px;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    left: calc(50% - 250px);
    text-align: center;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
    .NetworkErrorOverlay__panel__header {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 16px 32px;
      z-index: 200; }
    .NetworkErrorOverlay__panel__actions {
      display: flex;
      flex: 1;
      justify-content: flex-end; }
      .NetworkErrorOverlay__panel__actions > *:first-child {
        margin-left: 0; }
    .NetworkErrorOverlay__panel__options {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end; }
      .NetworkErrorOverlay__panel__options > *:last-child {
        margin-left: 0; }
    .NetworkErrorOverlay__panel__features {
      display: flex;
      justify-content: flex-start; }
      .NetworkErrorOverlay__panel__features > *:first-child {
        margin-left: 0; }
    .NetworkErrorOverlay__panel__content {
      z-index: 100; }
  .NetworkErrorOverlay__text {
    margin-bottom: 16px; }
  .NetworkErrorOverlay__button {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid transparent;
    color: #03854a;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    margin: 6px;
    min-width: 64px;
    padding: 0 16px;
    position: relative;
    text-align: center;
    transition: all 250ms ease-in-out;
    background-color: #0dac64;
    color: #fff; }
    .NetworkErrorOverlay__button:focus {
      outline: none; }
    .NetworkErrorOverlay__button:hover {
      cursor: pointer; }
    .NetworkErrorOverlay__button:disabled, .NetworkErrorOverlay__button--disabled {
      opacity: 0.4;
      box-shadow: none !important; }
    .NetworkErrorOverlay__button:focus {
      background-color: #097d48; }
