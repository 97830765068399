.FormSection__header, .FormSection-header {
  color: rgba(0, 0, 0, 0.87);
  font-size: 20px;
  font-weight: 500;
  color: #727272;
  font-weight: 300;
  margin: 0 -32px 24px;
  border-bottom: 1px solid #dfdfdf;
  padding: 0 32px 8px;
  padding-top: 16px; }
